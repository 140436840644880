import { Title, WidthLimiter } from '@atoms';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EquipmentTubsAndTraysJPG, RangementJPG, TraysJPG, TubsJPG } from '@images/misc';
import { useTitle } from '@utils';
import { Col, Image, Row } from 'antd';
import React from 'react';

const TubsAndTrays = (): JSX.Element => {
  useTitle('Tubs and Trays');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Tubs and Trays</Title>
        <Row gutter={[24, 24]} style={{ margin: '24px 0' }}>
          <Col span={24}>
            <p>
              Les Drs LALY et RETTEL ont mis en place au sein de leur cabinet dentaire une organisation de travail en
              Tubs and Trays (ou “boites et cassettes”).
            </p>
            <p>
              Cette technique innovante a été préconisées par le Dr Kilpatrick il y a plus de 35 ans déjà pour permettre
              d'optimiser l'ergonomie et l'asepsie au sein des cabinets dentaires.
            </p>
          </Col>
        </Row>

        <h3>En quelques mots</h3>
        <Row gutter={[24, 24]} align="middle" style={{ margin: '24px 0' }}>
          <Col md={12} xs={24}>
            <h4>les Tubs</h4>
            <p>
              Les Tubs sont des boites de couleurs différentes, compartimentées, regroupant tous les produits non
              stérilisables utilisés pour le soin dentaire. Il y a autant de boites de couleurs différentes que de types
              de soins dentaires existants.
            </p>
          </Col>
          <Col md={10} xs={24}>
            <Image src={TubsJPG} alt="Tubs" style={{ width: '100%' }} />
          </Col>

          <Col md={10} xs={24}>
            <Image src={TraysJPG} alt="Tubs" style={{ width: '100%' }} />
          </Col>
          <Col md={12} xs={24}>
            <h4>les Trays</h4>
            <p>
              Les Trays sont des cassettes métalliques, des plateaux d'instruments stériles pré-préparés en fonction des
              différents types de soins dentaires. Les instruments sont rangés dans l'ordre de leur utilisation.
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ margin: '24px 0' }}>
          <Col span={24}>
            <h4 style={{ marginTop: '24px' }}>
              Quels sont les intérêts du travail en Tub and Tray et quels sont les avantages pour l'équipe médicale et
              pour le patient ?
            </h4>

            <p>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px', opacity: 0.65 }} />
              L'ergonomie
            </p>

            <p>Le travail en Tubs and Trays permet</p>
            <ul>
              <li>
                un meilleur rangement des instruments dans les trays par leur ordre d'utilisation, ce qui assure une
                meilleure ergonomie de travail pour le praticien et pour son assistante lors du soin dentaire.
              </li>
              <li></li>d'éviter l'ouverture répétée des tiroirs en sortant uniquement le tub nécessaire à la réalisation
              du soin, évitant ainsi la contamination bactérienne. Le soin est alors réalisé de manière fluide et bien
              rythmé, ce qui assure une prise en charge optimale du patient.
            </ul>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <figure style={{ width: '50%' }}>
                <Image src={EquipmentTubsAndTraysJPG} alt="Matériel Tub and Trays" style={{ maxWidth: '100%' }} />
                <figcaption>Matériel Tubs and Trays</figcaption>
              </figure>
            </div>

            <p>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px', opacity: 0.65 }} />
              La sécurité
            </p>

            <p>
              Le rangement des instruments dans des cassettes métalliques permet la réduction de la manipulation
              d'instruments coupants et tranchants contaminés et assure ainsi une diminution du risque de blessures et
              d'exposition aux infections hématogènes. La sécurité de l'équipe médicale est alors renforcée lors de
              l'étape de nettoyage et de stérilisation du matériel.
            </p>

            <p>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px', opacity: 0.65 }} />
              L'asepsie
            </p>

            <p>
              Le rangement des instruments dans les cassettes assure à ces derniers une bonne tenue durant le nettoyage
              (exemple des instruments à charnière) et durant le cycle de stérilisation, ce qui permet des conditions
              optimales d'asepsie.
            </p>
            <p>
              De plus le rangement en cassettes permet également une facilité d'étiquetage et d'emballage ce qui permet
              d'améliorer la traçabilité, étape indispensable de la chaîne d'asepsie.
            </p>

            <figure>
              <img src={RangementJPG} alt="Photo de rangements" style={{ maxWidth: '100%' }} />
            </figure>

            <p>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px', opacity: 0.65 }} />
              L'accompagnement, confort du patient et qualité de soin
            </p>
            <p>
              Le travail en tubs and trays permet aux assistantes dentaires de réduire le temps de tri manuel et de
              nettoyage des instruments, ainsi que le temps de préparation des séquences de soin. De ce fait, l'équipe
              médicale au complet sera plus disponible pour accueillir le patient et le prendre en charge.
            </p>
            <p>
              L'assistante dentaire pourra alors assister le praticien au fauteuil et permettre d'améliorer la qualité
              des soins ainsi que le confort du patient. La gestion du stress sera également optimisée.
            </p>
          </Col>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default TubsAndTrays;
