import { Title, WidthLimiter } from '@atoms';
import { ADDRESS, SCHEDULE, TEL_NUMBER } from '@config';
import pages from '@pages';
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faPhoneAlt, faClock, faCreditCard, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { WelcomeSVG } from '@images/vectors';
import BookRdvButton from 'components/molecules/BookRdvButton';
import { useTitle } from '@utils';

const Contact = (): JSX.Element => {
  useTitle('Contact');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Row gutter={24} align="middle" justify="space-between">
          <Col md={12} xs={24}>
            <Title>Contact</Title>
            <p>Le cabinet dentaire et d'implantologie de Château-Thierry, reçoit sur rendez-vous.</p>

            <h3>Nos coordonnées</h3>
            <p>
              <FontAwesomeIcon icon={faMapMarkedAlt} style={{ marginRight: '8px', opacity: 0.65 }} />
              <strong>Adresse :</strong> {ADDRESS}
            </p>
            <p>
              <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: '8px', opacity: 0.65 }} />
              <strong>Prise de RDV :</strong> Par téléphone au {TEL_NUMBER}.
              <br /> Le secrétariat est disponible du lundi au vendredi, de 9h à 12h et 14h à 18h.
            </p>
            <p>
              <FontAwesomeIcon icon={faClock} style={{ marginRight: '8px', opacity: 0.65 }} />
              <strong>Horaires :</strong>
              <br />
              <code>Lun</code> : {SCHEDULE.mon}
              <br />
              <code>Mar</code> : {SCHEDULE.tue}
              <br />
              <code>Mer</code> : {SCHEDULE.wed}
              <br />
              <code>Jeu</code> : {SCHEDULE.thu}
              <br />
              <code>Ven</code> : {SCHEDULE.fri}
              <br />
              <Link to={pages.emergency.pathname} style={{ color: '#ff4d4f' }}>
                <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
                Urgence dentaires, cliquez ici.
              </Link>
            </p>
          </Col>
          <Col md={12} xs={24}>
            <WelcomeContainer>
              <SvgContainer>
                <WelcomeSVG />
              </SvgContainer>
              <BookRdvButton />
            </WelcomeContainer>
          </Col>
        </Row>

        <p>
          <FontAwesomeIcon icon={faCreditCard} style={{ marginRight: '8px', opacity: 0.65 }} />
          <strong>Moyens de paiement acceptés :</strong> Chéques, Espèces, Carte bleue
        </p>
        <p>
          Le Cabinet est équipé de lecteurs pour CARTE VITALE et transmet automatiquement à votre CPAM vos frais
          dentaires, vous permettant ainsi des remboursements accélerés ( 4 à 5 jours par votre CPAM puis environ 15
          jours par votre mutuelle) Facilité de paiement sur demande.
        </p>
      </WidthLimiter>
    </>
  );
};

export default Contact;

const WelcomeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  & > h1 {
    font-size: 2rem;
  }

  & > button {
    margin-top: 2px;
    transform: scale(1.2) translateY(-38px);
    margin-bottom: -42px;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  width: 200px;
`;
