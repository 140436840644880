import { GoogleMap, Title, WidthLimiter } from '@atoms';
import { ADDRESS } from '@config';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessibilityPlanJPG, AvailableTransportsJPG, ParkingPlan } from '@images/misc';
import { useTitle } from '@utils';
import { Col, Row } from 'antd';
import React from 'react';

const AccessPlan = (): JSX.Element => {
  useTitle("Plan d'accès");
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Plan d'accès</Title>
        <p>
          <FontAwesomeIcon icon={faMapMarkedAlt} style={{ marginRight: '8px', opacity: 0.65 }} />
          <strong>Adresse :</strong> {ADDRESS}
        </p>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Col md={11} xs={24}>
            <h3>Accessibilité</h3>
            <ul>
              <li>A 30min de Reims, 40min de Soissons et 40min de l'Ile de France (Disney)</li>
              <li>A 6min de la gare - Ligne directe Paris Est</li>
              <li>A 9 min de l'Autoroute A4 (direction Paris / Reims)</li>
              <li>Arrêt de bus à moins de 100m</li>
            </ul>
            <img src={AvailableTransportsJPG} alt="Transports disponibles" style={{ width: '100%' }} />
          </Col>
          <Col md={13} xs={24}>
            <img src={AccessibilityPlanJPG} alt="Plan d'accéssibilité" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} className="revert">
          <Col md={13} xs={24}>
            <img src={ParkingPlan} alt="Plan d'accéssibilité" style={{ width: '100%' }} />
          </Col>
          <Col md={11} xs={24}>
            <h3>Parkings</h3>
            <ul>
              <li>Parking, "Parc de stationnement". Zone bleue (1h30)</li>
              <li>Parking, "Parking public". Sans limite</li>
            </ul>
          </Col>
        </Row>
      </WidthLimiter>
      <GoogleMap height={'400pxpx'} />
    </>
  );
};

export default AccessPlan;
