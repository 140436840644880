/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import pages from '@pages';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt, faHome } from '@fortawesome/free-solid-svg-icons';
import { WidthLimiter } from '@atoms';
import { ADDRESS, TEL_NUMBER } from '@config';
import { InfoCovidModal } from '@organisms';
import SubMenu from 'antd/lib/menu/SubMenu';
import { LogoPNG } from '@images/misc';

const Header = (): JSX.Element => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [currentPathname, setCurrentPathname] = React.useState<string>(pathname);

  React.useEffect(() => {
    setCurrentPathname(pathname);
  }, [pathname]);

  const onMenuClick = React.useCallback(
    ({ key }: any) => {
      push(key);
      setCurrentPathname(key);
    },
    [push],
  );

  return (
    <Layout.Header>
      <InfosContainer>
        <div className="laptop-only">
          <span>
            <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: '8px', opacity: 0.65 }} /> {TEL_NUMBER}
          </span>
          <span>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '8px', opacity: 0.65 }} /> {ADDRESS}
          </span>
        </div>

        <div>
          <InfoCovidModal />
        </div>
      </InfosContainer>

      <BrandContainer>
        <img src={LogoPNG} alt={'Logo cabinet dr. Laly et Rettel'} style={{ width: '100%', maxWidth: '480px' }} />
      </BrandContainer>

      <MenuContainer>
        <Menu onClick={onMenuClick} selectedKeys={[currentPathname]} mode="horizontal" subMenuCloseDelay={0.3}>
          <Menu.Item key={pages.home.pathname}>
            <FontAwesomeIcon icon={faHome} /> <span className="laptop-only">Accueil</span>
          </Menu.Item>
          <Menu.Item key={pages.accessPlan.pathname}>Plan d'accès</Menu.Item>
          <Menu.Item key={pages.team.pathname}>L'équipe</Menu.Item>

          <SubMenu key="cabinet" title="Le cabinet">
            <Menu.Item key={pages.cabinet.pathname}>Notre cabinet</Menu.Item>
            <Menu.Item key={pages.equipment.pathname}>Notre matériel</Menu.Item>
            <Menu.Item key={pages.radios.pathname}>Radios</Menu.Item>
            <Menu.Item key={pages.tubsAndTrays.pathname}>Tubs and Tray</Menu.Item>
            <Menu.Item key={pages.stress.pathname}>Zen</Menu.Item>
            <Menu.Item key={pages.anecdotes.pathname}>Anecdotes</Menu.Item>
          </SubMenu>

          <SubMenu key="treatments" title="Traitements">
            <Menu.Item key={pages.odontology.pathname}>Odontologie conservatrice</Menu.Item>

            <SubMenu key="esthetic" title="Esthétique">
              <Menu.Item key={pages.bleaching.pathname}>Blanchiment</Menu.Item>
              <Menu.Item key={pages.facet.pathname}>Facette</Menu.Item>
            </SubMenu>

            <Menu.Item key={pages.implantology.pathname}>Implantologie</Menu.Item>
            <Menu.Item key={pages.periodontology.pathname}>Parodontologie</Menu.Item>

            <SubMenu key="prothesis" title="Prothèses">
              <Menu.Item key={pages.prosthesisComplete.pathname}>Prothèse complète</Menu.Item>
              <Menu.Item key={pages.prosthesisFixed.pathname}>Prothèse fixée</Menu.Item>
            </SubMenu>

            <Menu.Item key={pages.prf.pathname}>PRF</Menu.Item>

            <Menu.Item key={pages.kids.pathname}>Enfants</Menu.Item>

            <Menu.Item key={pages.emergency.pathname} danger>
              Urgence
            </Menu.Item>
          </SubMenu>

          <Menu.Item key={pages.emergency.pathname} danger>
            Urgence
          </Menu.Item>
          <Menu.Item key={pages.contact.pathname}>Contact</Menu.Item>
          {/*
          <Menu.Item key={pages.search.pathname}>
            <FontAwesomeIcon icon={faSearch} />
          </Menu.Item>
          */}
        </Menu>
      </MenuContainer>
    </Layout.Header>
  );
};

export default Header;

const InfosContainer = styled(WidthLimiter)`
  position: fixed;
  width: 100%;
  z-index: 100;
  background: #f7f7f7;
  border-bottom: 1px solid #eaeaea;

  & > div {
    display: flex;
    justify-content: space-between;
  }

  & > div > div > span:first-child {
    padding: 4px 12px 4px 0px;
  }

  & > div > div > span:not(:first-child) {
    padding: 4px 12px;
  }

  & > div > div > span:nth-child(n):not(:last-child) {
    border-right: 1px solid #eaeaea;
  }

  & > div > div {
    display: inline-flex;
    height: 42px;
    align-items: center;
  }
`;

const BrandContainer = styled(WidthLimiter)`
  margin-top: 42px;
  padding: 12px;
  text-align: center;

  & > div {
    font-size: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div > h1 {
    color: #00abe4;
    margin: 0;
  }
`;

const MenuContainer = styled(WidthLimiter)`
  line-height: 32px;
  border: 1px solid #f0f0f0;

  & > div {
    background-color: white;
  }

  & > div > ul {
    border: none;
    padding: 14px 0;
    max-width: 100vw;
    justify-content: center;
  }

  & > div > ul .ant-menu-overflow-item-rest:after {
    display: none;
  }

  & > div > ul .ant-menu-submenu-horizontal:after {
    display: none;
  }

  & > div > ul .ant-menu-item:after {
    display: none;
  }

  & > div > ul > li {
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    letter-spacing: 1.5px;
  }

  & > div > ul > li:hover {
    border-bottom: 1px solid;
  }
`;
