import { Title, WidthLimiter } from '@atoms';
import { TEL_NUMBER } from '@config';
import { faChevronRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTitle } from '@utils';
import { Alert, Row, Col } from 'antd';
import React from 'react';

const Emergency = (): JSX.Element => {
  useTitle('Urgence dentaire');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Urgence dentaire</Title>
        <Alert
          style={{ textAlign: 'center', marginTop: '24px' }}
          message={'Le pôle dentaire accepte les urgences uniquement sur rendez-vous au jour le jour.'}
          description={`Il est nécessaire d'appeler au ${TEL_NUMBER} avant 11h le matin.`}
        />

        <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
          <Col span={24}>
            <h3>Qu'est ce qu'une urgence dentaire ?</h3>
            <p>
              Il existe plusieurs types d'urgence dentaire récurrentes que nous prenons en charge dans notre cabinet
              dentaire.
              <br />
              Vous trouverez ci dessous des explications et les traitements proposés par votre chirurgien dentiste.
            </p>

            <h4>Bourrage alimentaire ou syndrome du Septum</h4>
            <p>
              Cette urgence fait suite à une accumulation d'aliments entre deux dents, avec en général un mauvais point
              de contact) ou une carie. Les aliments restent stagnants entre les 2 dents provoquant une inflammation de
              la gencive entrainant des douleurs importantes lors de la mastication.
            </p>
            <p>
              Le traitement qui sera effectué par notre chirugien dentiste consistera à refaire le plombage ou le
              composite et/ou traiter la carie ou rélaiser une couronne si le délabrement est important.
            </p>
            <h4>Rage de dents ou pulpite dentaire</h4>
            <p>
              La rage de dents est l'urgence dentaire la plus répandue en France. Elle se manifeste sur une dent
              délabrée ou fortement cariée qui atteind la pulpe dentaire.
            </p>
            <p>
              La lésion carieuse atteint la pulpe dentaire ou nerf dentaire qui est richement innervée et vascularisée.
              Tout ces éléments sont cloîtrer dans une cavité inextensible.
            </p>
            <p>
              Ces conditions créent une inflammation du nerf de la dent responsable de cette douleur qui ne cède pas aux
              antalgiques, la douleur devenant insupportable jusqu'à vous réveiller la nuit et/ou vous empêcher de
              dormir.
            </p>
            <p>
              Il faut consulter immédiatement et en urgence le pôle dentaire et absolument éviter l'automédication
              notement avec des anti-inflammatoires.
            </p>
            <p>
              La séance de soin réalisée en urgence consistera à réaliser une pulpotomie c'est à dire retirer la partie
              du nerf de la couronne responsable de la douleur puis poser un pansement provisoire, le temps que la dent
              redevienne asymptomatique.
            </p>
            <p>
              La suite du traitement consistera à finir la dévitalisation de la dent puis réaliser une couronne dentaire
              en céramique afin de protéger cette dernière.
            </p>

            <h4>Dent cassée ou fracturée</h4>
            <p>
              <strong>
                Cette urgence concerne le plus souvent les enfants mais également les adultes pratiquant des sports
                violents ou lors d'une chute accidentelle.
              </strong>
            </p>
            <p>
              Si la pulpe dentaire n'est pas atteinte, le dentiste pourra recoller le morceau ou reconstruire la dent à
              l'aide d'une résine composite résistante qui aura une teinte identique à celle de votre dent garantissant
              un résultat esthétique optimal.
            </p>
            <p>
              Si la pulpe dentaire est atteinte, le dentiste reconstruira la partie coronaire de la dent par une
              couronne dentaire le plus souvent après avoir réalisé la dévitalisation des racines dentaires.
            </p>
            <p>
              Si la fracture se situe au niveau de la racine, suivant sa localisation plus ou moins profonde, votre
              dentiste vous proposera soit un traitement de compromis ou l'extraction et la pose d'un implant dentaire.
            </p>

            <h4>Abcès dentaires</h4>
            <p>
              <strong>Il s'agit d'une accumulation de germes bactériens au niveau d'une dent ou de la gencive.</strong>
            </p>
            <p>
              En général, l'abcès survient lors du non-traitement d'une carie qui perdure dans le temps. En effet, le
              nerf de la dent peut se nécroser et envoyer dans les racines des toxines bactériennes responsables de
              développement d'abcès dentaire.
            </p>

            <p>Les symptômes sont les suivants :</p>
            <ul>
              <li>Sentiment de fatigue général ou d'épuisement</li>
              <li>Fièvre possible</li>
              <li>Oedème au niveau du visage ou de la machoire</li>
              <li>Difficulté d'ouverture de la bouche-trismus</li>
              <li>Une douleur marquée avec la prise d'aliment plûtot chaud</li>
              <li>Une douleur à la pression lors de la mastication des aliments</li>
            </ul>

            <p>
              Le traitement va consister par l'administration d'antibiotiques à large spectre tel que l'amoxiciline et
              métronidazole ainsi que des bains de bouche d'eau tiède salée afin de décongestionner la zone, puis votre
              chirurgien dentiste évacuera le pus collecté au niveau de la gencive. ( Ne pas s'automédiquer et prendre
              rendez vous rapidement chez votre dentiste{' '}
            </p>
            <p>
              Il faudra par la suite procéder à la dévitalisation de la dent puis sa reconstruction par la pose d'une
              couronne dentaire en céramique.
            </p>
            <p>Important : On remarque une part importante d'abcès chez les patients qui ont peur du dentiste.</p>
            <p>
              En effet, ces derniers ne consultent qu'au dernier moment ce qui favorise l'apparition des abcès. Sachez
              que les dentistes du pôle dentaire savent appréhender vos peurs et gérer votre douleur. De plus, nous
              utilisons les dernières technologies en matière d'anesthésie ce qui garantit des soins sans douleurs.
              N'hésitez ps à consulter notre article sur la phobie du dentiste.
            </p>

            <p>
              <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
              Comment prévenir un abcès dentaire ?
            </p>

            <p>L'hygiène bucco-dentaire est un facteur clef de la prévention des abcès dentaires.</p>
            <p>
              Une hygiène quotidienne avec une brosse à dent souple et un dentifrice fluoré. L'utilisation du fil
              dentaire et d'un jet dentaire favorise également la bonne hygiène dentaire.
            </p>
            <p>Une alimentation équilibrée permet de conserver un bon état bucco-dentaire.</p>
            <p>
              Et bien évidemment une visite annuelle au cabinet pour que votre chirurgien-dentiste s'assure de votre
              bonne santé dentaire et réalise un détartrage.
            </p>
          </Col>
          <Col span={24}>
            <h3>La gestion de la douleur dans notre cabinet dentaire :</h3>

            <p>
              Une des préoccupations majeures qui occupe l'esprit de nos patients quand ils se rendent chez le
              chirurgien-dentiste : la gestion de la douleur mis en place par le chirurgien-dentiste et la confiance qui
              peut lui être accordée pour réaliser les soins dentaires.
            </p>
            <p>
              Ces deux paramètres sont étroitement liés, car sans un minimum confiance, le stress et l'anxiété
              s'installent et augmentent d'autant le ressenti de la douleur, dont le seuil de déclenchement ainsi que et
              les effets diffèrent d'un patient à l'autre.
            </p>
            <p>Lors d'une consultation pour une urgence dentaire, le praticien évaluera les paramètres suivants :</p>

            <h4>L'intensité de la douleur</h4>
            <p>
              Le niveau de la douleur s'évalue généralement selon une échelle numérique de 1 à 10, divisée en trois
              paliers :
            </p>
            <ul>
              <li>Premier palier (de 1 à 4) : douleur modérée.</li>
              <li>Deuxième palier (de 4 à 7) : douleur moyenne.</li>
              <li>Troisième palier (de 7 à 10) : douleur intense.</li>
            </ul>

            <p>
              En fonction de l‘intensité de la douleur, le chirurgien-dentiste mettra en place un plan de traitement
              technique (éviction de la carie, dévitalisation de la dent ou extraction) ainsi qu'un traitement
              médicamenteux adapté.
            </p>

            <h4>Les traitements anti-douleur</h4>
            <p>
              Selon les paliers d'intensité de la douleur, les prescriptions se répartissent principalement en trois
              catégories d'antalgiques :
            </p>
            <ul>
              <li>Premier palier : le paracétamol, à dosage et durée de traitement modulables selon les cas.</li>
              <li>Deuxième palier : paracétamol codéiné</li>
              <li>Troisième palier : les dérivés morphiniques.</li>
            </ul>

            <h4>Climat de confiance</h4>
            <p>
              De l'extraction d'une dent à la pose d'implant dentaire, ou pour soigner une simple carie, les
              chirurgiens-dentistes réalisent des anesthésies locales afin que l'ensemble des soins se réalise le plus
              confortablement possible, pendant l'opération mais aussi après.
            </p>
            <p>
              Afin d ‘instaurer un climat de soin plus propice à la détente nous proposons également des thématiques
              musicales relaxantes lors des soins.
            </p>
            <p>
              C'est pour cela que dans notre cabinet dentaire, notre équipe pluridisciplinaire veille à vous accompagner
              dans toutes les étapes de votre traitement et vous prodigue des soins de très bonne qualité.
            </p>
          </Col>

          <Col span={24}>
            <h3>Service de garde d'urgence dentaire</h3>
            <p>
              Le Conseil de l'Ordre Départemental des Chirurgiens-Dentistes organise un service de garde pour les
              dimanche, jours fériés et durant le mois d'aout.
            </p>
            <p>
              Vous obtiendrez les coordonnées du dentiste de garde en téléphonant au commissariat ou à la gendarmerie de
              votre ville.
            </p>
            <p>
              En cas de traumatisme dentaire (suite à un choc ou une chute), nous vous invitons à prendre contact au
              plus tôt avec le cabinet dentaire ou avec l'hôpital le plus proche (service odontologie ou stomatologie et
              chirurgie maxillo-faciale).
            </p>
          </Col>
        </Row>

        <p>
          <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '8px', opacity: 0.65 }} />
          <strong>En cas d'urgence dentaire, nous contacter dans les plus brefs délais.</strong>
        </p>
      </WidthLimiter>
    </>
  );
};

export default Emergency;
