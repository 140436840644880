export const ADDRESS = '9 rue Vallée, 02400 Château-Thierry';
export const TEL_NUMBER = '03 23 82 01 26';
export const MAIL = 'docteurslalyetrettel@gmail.com';
export const SCHEDULE = {
  mon: '09h – 19h',
  tue: '09h – 19h',
  wed: '09h – 19h',
  thu: '09h – 19h',
  fri: '09h – 19h',
};
