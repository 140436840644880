import { Title, WidthLimiter } from '@atoms';
import { useTitle } from '@utils';
import { Alert } from 'antd';
import React from 'react';

const BrushingTeeth = (): JSX.Element => {
  useTitle('Hygiène dentaire');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Hygiène dentaire</Title>
        <Alert
          style={{ textAlign: 'center', margin: '24px 0' }}
          message={
            "Notre cabinet rentre dans une démarche de prévention et d'hygiène bucco-dentaire afin d'assurer la pérennité des travaux réalisés et de maintenir les dents en bonne santé."
          }
          description={
            <>
              Pour cela nous préconisons la mise en place d'un programme de prévention spécifique et original que votre
              dentiste pourra vous prescrire.
              <br />
              Parlez en lors de votre prochain RDV.{' '}
            </>
          }
        />
        <h3>Comment se brosser les dents ?</h3>
        <p>
          Le brossage bien fait, à la fréquence nécessaire a pour but le maintien de dents saines et l'élimination de la
          plaque dentaire. La brosse à dents manuelle ou électrique doit être à poils souples en fibres synthétiques, et
          maniable
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <iframe
            src="https://www.youtube.com/embed/_rPFOWAfeU0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            scrolling="no"
            style={{ marginBottom: '24px', width: '100%', maxWidth: '560px', height: '315px' }}
          />
        </div>

        <h4>Combien de fois par jour ?</h4>
        <p>
          Un brossage deux fois par jour n'est pas suffisant. IL est impératif de se brosser les dents après chaque
          repas : après un excellent brossage, il reste entre 100 et 1000 bactéries par mm² et au même endroit, deux
          heures après, on en dénombre 2 millions. Le brossage à midi est donc indispensable.
        </p>
        <h4>Choix de la brosse à dents</h4>
        <p>
          La caractéristique la plus importante est la souplesse qui est fonction de la longueur du poil et de son
          diamètre. Il faut, dans tous les cas, acheter une brosse à dents souple qui est la seule qui permet d'avoir un
          brossage efficace et non agressif. Le brossage avec une brosse à dent dure entraîne régulièrement des
          récessions parodontales.
        </p>
        <h4>Choix du dentifrice</h4>
        <p>
          Le dentifrice a pour intérêt principal de faciliter l'élimination de la plaque dentaire. Le principal principe
          actif du dentifrice est le fluor. Il renforce les couches superficielles de l'émail et les rend plus
          résistantes aux acides produits par les bactéries. Chez l'adulte, le dentifrice doit contenir au moins 1.000
          ppm (parties pour millions) de fluor pour être efficace.
        </p>
        <h4>Les techniques de brossage</h4>
        <p>
          Ce n'est pas le temps que l'on passe à se laver les dents qui permet un brossage efficace. Il faut avant tout
          favoriser la méthode. Le meilleur moyen de ne pas oublier de secteur, c'est de toujours suivre le même schéma
          et ce, de façon méthodique. Les dents sont nettoyées l'une après l'autre. Pour chaque arcade dentaire, le
          brossage commence par la face externe de la dernière dent d'un côté et se termine sur la face interne de la
          même dent du même côté. Pour des raisons d'efficacité (acquérir un automatisme), le brossage doit être fait de
          la même façon à chaque fois. Il ne faut pas oublier de brosser la langue qui joue un rôle important dans
          l'hygiène bucco-dentaire. En effet les bactéries que l'on trouve sur la plaque, se retrouvent aussi sur la
          langue.
        </p>
      </WidthLimiter>
    </>
  );
};

export default BrushingTeeth;
