import React from 'react';
import styled from 'styled-components';
import { Carousel, Col, Row, Image, Button } from 'antd';
import { Title, WidthLimiter } from '@atoms';
import { useTitle } from '@utils';
import {
  PhotoAnne,
  PhotoArnaud,
  PhotoCathie,
  PhotoCeline,
  PhotoClotilde,
  PhotoGwladys,
  PhotoJohanna,
  PhotoLouise,
  PhotoPatrick,
  PhotoSabrina,
  PhotoThibaut,
  TeamPhoto2,
} from '@images/team';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { InstagramOutlined } from '@ant-design/icons';

const teamMembers = [
  <Row key="1" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={16} xs={24}>
      <h3 className="smartphone-only">L'équipe</h3>
      <Image src={TeamPhoto2} alt="Photo de l'équipe" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={8} xs={24}>
      <h3 className="laptop-only">L'équipe</h3>
      <p>
        L'équipe dentaire du Pôle Santé de la Tour est composée de 3 dentistes, les docteurs LALY, RETTEL et BACHY,
        entourés d'une secrétaire, Anne, et de 4 assistantes dentaires, Gwladys, Clotilde, Cathie et Sabrina, les
        “abeilles du cabinets”.
      </p>
      <p>
        La cohésion d'équipe est notre priorité pour rendre accueillante et chaleureuse votre prise en charge au cabinet
        dentaire. Et si notre discipline demande rigueur et concentration, le sourire et la bonne humeur reste notre
        force du quotidien.
      </p>
    </Col>
  </Row>,

  <Row key="2" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Dr LALY Céline</h3>
      <Image src={PhotoCeline} alt="Photo du docteur Laly Céline" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Dr LALY Céline</h3>
      <ul style={{ paddingLeft: '24px' }}>
        <li>Docteur en chirurgie dentaire diplômée de la faculté dentaire de Paris 7, Garancière.</li>
        <li>DU d'implantologie Orale et maxillo-faciale de l'université de Paris Diderot Créteil</li>
        <li>CES de chirurgie dentaire de parodontologie de Paris 5 Montrouge</li>
        <li>Attestation universitaire d'implantologie</li>
        <li>Certificat de prothèse implantaire de l'association BIO.</li>
        <li>
          Certificat de formation à la Tunnelisation, concept global en chirurgie Plastique parodontale par les Pr RONCO
          et DAVID
        </li>
        <li>Attestation de formation sur les techniques de Summers et Bone Splitting par le Dr Franck Zerah</li>
        <li>Académie du Sourire d'Aix en Provence par le Dr PARIS, et FAUCHER.</li>
      </ul>
    </Col>
  </Row>,
  <Row key="3" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Dr RETTEL Thibaut</h3>
      <Image src={PhotoThibaut} alt="Photo du docteur Rettel Thibaut" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Dr RETTEL Thibaut</h3>
      <ul style={{ paddingLeft: '24px' }}>
        <li>Dr en chirurgie dentaire diplômé de la faculté de Reims</li>
        <li>Attestation universitaire d'implantologie</li>
        <li>
          Diplômé du centre d'enseignement des thérapeutiques orthodontiques orthopédiques et fonctionelles de Paris
        </li>
      </ul>
    </Col>
  </Row>,
  <Row key="4" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Dr BACHY Louise</h3>
      <Image src={PhotoLouise} alt="Photo du docteur Bachy Louise" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Dr BACHY Louise</h3>
      <ul style={{ paddingLeft: '24px' }}>
        <li>Dr en chirurgie dentaire diplômé de la faculté de Reims</li>
        <li>CES de parodontologie de Reims</li>
      </ul>
    </Col>
  </Row>,
  <Row key="5" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Anne</h3>
      <Image src={PhotoAnne} alt="Photo d'Anne" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Anne</h3>
      <ul style={{ paddingLeft: '24px' }}>
        <li>Secrétaire médicale et aide dentaire</li>
      </ul>
      <p>
        Son sourire est la première chose que vous verrez en franchissant les portes du cabinet. Anne vous accueille et
        vous guide pour toutes les démarches administratives (prises de RDV, télétransmissions, sécurité sociale,
        mutuelles, comptabilité...). Vous la trouverez d'ailleurs souvent perdue dans ses papiers.
      </p>

      <p>
        Sérieuse et réfléchie, doyenne du cabinet (aussi appelée “maman”), elle mène la barque avec rigueur, mais il est
        toutefois très facile de lui provoquer un fou rire. Amateurs de blagues ? Essayez, vous verrez...
      </p>
    </Col>
  </Row>,
  <Row key="6" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Gwladys</h3>
      <Image src={PhotoGwladys} alt="Photo de Gwladys" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Gwladys</h3>
      <ul style={{ paddingLeft: '24px' }}>
        <li>Assistante dentaire qualifiée</li>
        <li>Responsable assistantes et coordinatrice de cabinet</li>
        <li>Certificat de formation d'assistante en implantologie (société Zimmer Biomet)</li>
      </ul>
      <p>
        Gwladys vous accueille et vous guide durant tout votre parcours de soins au cabinet dentaire. Elle assiste les
        Docteurs au fauteuil grâce à un travail à 4 mains pour les soins classiques ou pour la chirurgie implantaire et
        pré-implantaire.
      </p>

      <p>
        Elle s'occupe également de vos ententes financières lors de RDV spécifiques où elle répondra à toutes vos
        questions.
      </p>

      <p>
        Sa minutie et son organisation hors norme en font également une excellente coordinatrice. Mais, pour la charmer,
        il vous faudra juste connaître son péché mignon : Les KINDER, sous toutes leurs formes, la font fondre à tous
        les coups...
      </p>
    </Col>
  </Row>,
  <Row key="7" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Clotilde</h3>
      <Image src={PhotoClotilde} alt="Photo de Clotilde" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Clotilde</h3>
      <ul style={{ paddingLeft: '24px' }}>
        <li>Assistante dentaire qualifiée</li>
        <li>Responsable de la gestion fonctionnelle et organisationnelle du cabinet</li>
        <li>Certificat de formation d'assistante en implantologie (société Zimmer Biomet)</li>
      </ul>
      <p>
        Clotilde vous accueille et vous guide durant tout votre parcours de soins au cabinet dentaire. Elle assiste les
        Docteurs au fauteuil grâce à un travail à 4 mains pour les soins classiques ou pour la chirurgie implantaire et
        pré-implantaire.
      </p>

      <p>
        Très observatrice et réfléchie, elle possède une logique faisant d'elle une excellente gestionnaire. Les
        coulisses du cabinet n'ont plus de secret pour elle. Et si sa tête n'oublie jamais rien, il est toutefois très
        facile de la déconcentrer car sa volubilité pourra vous suivre dans des discussions sur n'importe quel sujet
        pendant des heures.
      </p>
    </Col>
  </Row>,
  <Row key="8" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Cathie</h3>
      <Image src={PhotoCathie} alt="Photo de Cathie" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Cathie</h3>
      <ul style={{ paddingLeft: '24px' }}>
        <li>Assistante dentaire qualifiée</li>
        <li>Assistante en chirurgie implantaire </li>
      </ul>
      <p>
        Cathie vous accueille et vous guide durant tout votre parcours de soins au cabinet dentaire. Elle assiste les
        Docteurs au fauteuil grâce à un travail à 4 mains pour les soins classiques ou pour la chirurgie implantaire et
        pré-implantaire.
      </p>

      <p>
        Sa douceur et sa bienveillance vous feront vous sentir chez vous dès votre arrivée. Elle saura vous mettre à
        l'aise et vous rassurer grâce à sa bonne humeur. Son sourire est communicatif et fait sa force pour accompagner
        les docteurs lors de soins particuliers ou pour la prise en charge des enfants au cabinet dentaire.
      </p>

      <p>Courageuse et appliquée, elle a toutefois un petit grain de folie qui ravira les plus timides.</p>
    </Col>
  </Row>,
  <Row key="9" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Sabrina</h3>
      <Image src={PhotoSabrina} alt="Photo de Sabrina" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Sabrina</h3>
      <ul style={{ paddingLeft: '24px' }}>
        <li>Assistante dentaire en contrat de professionnalisation</li>
        <li>"Instagrameuse du cabinet"</li>
      </ul>
      <p>
        Sabrina vous accueille et vous guide durant tout votre parcours de soins au cabinet dentaire. Elle assiste les
        Docteurs au fauteuil grâce à un travail à 4 mains pour les soins classiques. Dernière recrue du cabinet, Sabrina
        est motivée et perfectionniste.
      </p>

      <p>
        Parfaitement intégrée à l'équipe et rapidement devenue indispensable, cette “abeille” vous impressionnera par
        son dynamisme et sa pétillance. Elle saura prendre soin de vous en toute circonstance.
      </p>

      <p>
        Et si la curiosité vous gagne, sachez que c'est elle qui alimente notre profil{' '}
        <a
          href="https://instagram.com/cabinet_laly_rettel"
          target="_blank"
          rel="noreferrer"
          style={{ whiteSpace: 'nowrap' }}
        >
          <InstagramOutlined rev={undefined} style={{ fontSize: '1.2em', marginRight: '2px' }} /> Instagram...
        </a>
      </p>
    </Col>
  </Row>,
  <Row key="10" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Johanna</h3>
      <Image src={PhotoJohanna} alt="Photo de Johanna" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Johanna</h3>
      <ul style={{ paddingLeft: '24px' }}>
        <li>Diplôme d'ostéopathe à l'Ecole Supérieure d'Ostéopathie à Champs sur Marne</li>
        <li>Formation post-Grad en ostéopathie et médecine dentaire au CFPCO à Paris</li>
      </ul>
      <p>
        Passionnée par son métier, Johanna est la correspondante privilégiée au sein de notre équipe pour la prise en
        charge multidisciplinaire des SADAM (pathologies des articulations temporo-mandibulaires): prise en charge du
        bruxisme diurne ou nocturne (grincement des dents), des tensions musculaires maxillo-faciales…
      </p>

      <p>
        Sa gentillesse et sa douceur, ainsi que sa dextérité et son sérieux en ont fait un atout indispensable pour le
        soin de nos patients mais aussi de notre propre équipe. Et oui c'est bien elle qui nous remet d'aplomb quand
        bobo il y a…
      </p>
    </Col>
  </Row>,

  <Row key="11" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Patrick</h3>
      <Image src={PhotoPatrick} alt="Photo de Patrick" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Patrick</h3>
      <p>Notre coursier favori. Vous l'apercevrez forcement en fin d'après-midi au détour d'un couloir. </p>

      <p>
        Véritable source de bonne humeur, toujours une petite blague en réserve, il est celui qui emmène vos empreintes
        à l'un de nos laboratoires situé à Domptin et ramène vos prothèses au cabinet.{' '}
      </p>
    </Col>
  </Row>,

  <Row key="12" gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
    <Col md={10} xs={24} style={{ textAlign: 'center' }}>
      <h3 className="smartphone-only">Arnaud</h3>
      <Image src={PhotoArnaud} alt="Photo d'Arnaud" style={{ maxHeight: 455 }} />
    </Col>
    <Col md={14} xs={24}>
      <h3 className="laptop-only">Arnaud</h3>
      <p>
        Nono le bricoleur, technicien et interlocuteur privilégié des petits incidents du quotidien, voila un homme sans
        qui notre cabinet n'irait pas d'aussi bon train.{' '}
      </p>

      <p>
        Chez nous, rien n'est jamais cassé temps qu'Arnaud n'a pas essayé de réparer. Pour ça encore faut il le prendre
        par les sentiments. Un petit café et tout est réglé.{' '}
      </p>
    </Col>
  </Row>,
];

const Team = (): JSX.Element => {
  useTitle('Notre équipe');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Row gutter={24} align="middle" justify="space-between">
          <Col md={24} xs={24}>
            <Title>Notre équipe</Title>

            <CarouselStyled
              autoplay
              effect="fade"
              arrows
              nextArrow={
                <ArrowContainer>
                  <Button icon={<FontAwesomeIcon icon={faChevronRight} />} type="text" />
                </ArrowContainer>
              }
              prevArrow={
                <ArrowContainer>
                  <Button icon={<FontAwesomeIcon icon={faChevronLeft} />} type="text" />
                </ArrowContainer>
              }
              speed={10}
            >
              {teamMembers.map((el, index) => (
                <div key={index}>{el}</div>
              ))}
            </CarouselStyled>

            <SmallScreen>{teamMembers}</SmallScreen>
          </Col>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default Team;

const CarouselStyled = styled(Carousel)`
  @media screen and (max-width: 1100px) {
    display: none !important;
  }

  padding-bottom: 24px;

  & .slick-dots li {
    & button {
      height: 6px;
      background: #354d61;
    }
  }

  & .slick-dots .slick-active {
    & button {
      height: 6px;
      background: #8496a1 !important;
    }
  }

  & .slick-prev {
    left: -48px;
  }

  & .slick-next {
    right: -48px;
  }
`;

const ArrowContainer = styled.span`
  & > button svg {
    color: #8496a1;
  }

  & > button {
    background-color: #8496a111;
    border-radius: 6px;
  }
`;

const SmallScreen = styled.div`
  @media screen and (min-width: 1101px) {
    display: none !important;
  }

  @media screen and (max-width: 780px) {
    & > div.ant-row {
      & > div:first-child {
        & .ant-image {
          width: auto;
          max-width: 100%;

          & img {
            width: auto;
            max-width: 100%;
          }
        }
      }

      & > div:last-child {
        width: 100%;
        justify-content: left;
      }
    }
  }
`;
