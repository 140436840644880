import React from 'react';
import styled from 'styled-components';

interface WidthLimiterProps extends React.HTMLAttributes<HTMLDivElement> {
  maxWidth?: string;
  children?: React.ReactNode;
}

const WidthLimiter = ({ maxWidth, children, ...rest }: WidthLimiterProps): JSX.Element => (
  <Container {...rest}>
    <div style={{ width: maxWidth, maxWidth, padding: '0 24px' }}>{children}</div>
  </Container>
);

export default WidthLimiter;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

WidthLimiter.defaultProps = {
  maxWidth: '1080px',
};
