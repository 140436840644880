import { Title, WidthLimiter } from '@atoms';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bleaching1JPG, Bleaching2JPG, Bleaching3JPG } from '@images/misc';
import { useTitle } from '@utils';
import { Col, Row } from 'antd';
import React from 'react';

const Bleaching = (): JSX.Element => {
  useTitle('Éclaircissement dentaire');
  return (
    <WidthLimiter style={{ padding: '24px 0' }}>
      <Title>L'éclaircissement dentaire</Title>
      <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
        <Col md={12} xs={24}>
          <h3>ICON : Érosion infiltration</h3>
          <p>
            Le traitement à l'ICON permet la gestion des hypominéralisations et de l'hypoplasie de l'émail.
            L'hypominéralisation se traduit par une coloration blanche ou brune sur la dent permanente et correspond à
            un défaut de la structure de l'émail. Ce défaut entraîne un effet d'optique renvoyant à l'oeil un effet de
            “tâche” sur la dent. Le traitement par l'icon est un traitement très peu invasif et permet de dissimuler cet
            effet d'optique car la résine aura les mêmes propriétés que l'émail.
          </p>
        </Col>
        <Col md={10} xs={24}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={Bleaching1JPG} alt="Erosion infiltration" style={{ maxWidth: '70%' }} />
          </div>
        </Col>

        <Col span={24}>
          <h4>Reconnaître l'hypominéralisation de l'émail</h4>
          <p>
            <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
            <strong>Le leucome précieux ou "White spot"</strong> est une hypominéralisation située au collet des dents
            permanentes, souvent retrouvé après un traitement orthodontique ou dû à un défaut d'hygiène. Cette lésion
            est peu profonde (tiers externe de l'émail) d'aspect blanc.
          </p>

          <p>
            <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
            <strong>La fluorose</strong> : c'est un trouble de la formation de l'émail dû à un excès de fluor. Cette
            lésion a un aspect blanc nuageux ou en bande, avec des couleurs très saturées. La symétrie de cette lésion
            la rend facilement reconnaissable. Elle est moyennement profonde (jusqu'où-au tiers moyen de l'émail).
          </p>

          <p>
            <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
            <strong>Le traumatisme dentaire</strong> : c'est un trouble de la formation de l'émail de la dent
            permanente, suite à un ancien traumatisme sur la dent lactéale. Une “tâche” ponctuelle apparait alors lors
            de l'éruption de la dent définitive. Elle s'étend jusqu'au tiers moyen de l'émail.
          </p>

          <p>
            <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
            <strong>Le MIH </strong> (hypomineralisation molaires et incisives) : c'est une hypominéralisation de
            l'émail ne concernant que les incisives et les molaires. Cette lésion est plus profonde (tiers profond
            interne de l'émail). Aucune étiologie n'a été mise en évidence à ce jour. L'émail est alors friable, de
            coloration jaunâtre ou brune et beaucoup plus enclin à la carie. Une sensibilité accrue au niveau de ces
            dents rend parfois les soins plus compliqués, d'où l'intérêt d'un traitement peu invasif préventif.
          </p>

          <h4>Reconnaître l'hypominéralisation de l'émail</h4>
          <p>
            Le traitement par ICON est un traitement peu invasif qui consiste à infiltrer de la résine jusqu'u coeur de
            la “tâche” pour contrer l'effet d'optique et permettre de retrouver une coloration homogène de la dent.
            Réalisée sous champ opératoire, cette technique innovante peut être accompagnée d'une restauration par
            stratification au composite dans le cas où la lésion est très profonde.
            <br />
            Ce traitement peut également être précédé d'un éclaircissement de la ou des tâches.
          </p>
        </Col>

        <Col md={12} xs={24}>
          <h3>L'éclaircissement des dents pulpées</h3>
          <p>
            Plus communément appelé le “blanchiment” dentaire par défaut, cette technique permet de traiter les
            dyschromies intrinsèques en ciblant les pigments organiques que le dent a ingéré au fil des années. Possible
            à la réalisation à partir de 18 ans, cette technique a fait ses preuves et permet un résultat fiable.
            <br />
            L'éclaircissement peut se réaliser en ambulatoire (à la maison) ou au cabinet dentaire (toujours suivi par
            quelques jours d'ambulatoire).
          </p>
        </Col>

        <Col md={10} xs={24}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={Bleaching2JPG} alt="Dents pulpées" style={{ maxWidth: '70%' }} />
          </div>
        </Col>

        <Col span={24}>
          <h4>L'éclaircissement au fauteuil</h4>
          <p>
            La technique d'éclaircissement au fauteuil est réalisée en cabinet dentaire, sous la supervision permanente
            de votre chirurgien dentiste et de ses assistantes. La séance dure entre 1h30 et 2h.
            <br />
            Le chirurgien dentiste protège les gencives et les muqueuses par une digue liquide, et applique un produit à
            bas de peroxyde d'hydrogène à 6% sur les dents, activé par une lampe. Ce produit est renouvelé plusieurs
            fois.
            <br />
            Le résultat définitif est obtenu après cette séance, c'est l'effet “Wouah” largement apprécié par les
            patients. Un entretien de quelques jours en ambulatoire est nécessaire.
          </p>

          <h4>L'éclaircissement en ambulatoire</h4>
          <p>
            La technique d'éclaircissement en ambulatoire est une alternative à la technique d'éclaircissement au
            fauteuil, ou le patient gère l'application du produit par un système de gouttière à remplir. Le produit
            utilisé est alors du peroxyde de carbamide entre 11 et 18%.
            <br />
            1h30 d'application de produit au fauteuil correspond à 2-3 semaines d'application de produit à la maison. Il
            faut donc une application de 4h par jour minimum sur 4 à 6 semaines de temps pour avoir un résultat
            définitif.
          </p>

          <p>
            <i style={{ display: 'block' }}>- Quelle est la stabilité du résultat dans le temps ?</i>
            En contrôlant les facteurs de risque de coloration comme le café, les colorants alimentaires, le vin... le
            résultat sera pérenne sur 1 à “ ans. Une maintenance sera alors nécessaire pour garder un résultat optimal,
            avec une gouttière utilisée en ambulatoire sur quelques jours.
          </p>

          <p>
            L'éclaircissement, qu'il soit réalisé en ambulatoire ou au fauteuil, n'entraîne pas de sensibilités
            accentuées s'il est bien supervisé et ne dégrade pas les dents.
          </p>

          <p>
            Ce traitement est contre-indiqué pour les mineurs, les femmes enceinte ou allaitante et en cas d'amélogénèse
            imparfaite et dentinogénèse imparfaite.
          </p>
        </Col>

        <Col md={12} xs={24}>
          <h3>L'éclaircissement des dents dépulpées</h3>
          <p>
            Plus communément appelé l'éclaircissement interne, cette technique permet de traiter les dyschromies qui se
            sont installées sur les dents dévitalisées. En effet, une dent dévitalisées et non reconstituée à l'aide
            d'une prothèse dentaire peut changer de teinte au fil des ans.
            <br />
            Un produit à base de peroxyde d'hydrogène à 35 ou 40% est appliquées dans la chambre camérale de la dent
            après réalisation d'un accès minime. Ce produit est renouvelé autant de fois que nécessaire jusqu'à
            l'obtention de la teinte désirée (soit que le dent retrouve la même teinte que les autres dents)
          </p>
        </Col>

        <Col md={10} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <img src={Bleaching3JPG} alt="Dents dépulpées" style={{ maxWidth: '70%' }} />
          </div>
        </Col>

        <Col span={24}>
          <p>
            Ce traitement est peu invasif et permet surtout de conserver l'intégrité tissulaire de la dent en évitant
            d'avoir recours à une prothèse dentaire type couronne ou facette. La dent est éligible si elle n'est pas
            détruite.
          </p>

          <p>
            Toutes ces techniques d'éclaircissement ont un résultat optimisé avec un bon maintien de l'hygiène
            bucco-dentaire et d'un équilibre alimentaire.
          </p>
        </Col>
      </Row>
    </WidthLimiter>
  );
};

export default Bleaching;
