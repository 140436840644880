import React from 'react';
import { Switch, Route } from 'react-router-dom';
import pages, { NotFound } from '../../../pages';
import { Layout } from 'antd';

const Content = (): JSX.Element => {
  return (
    <Layout.Content>
      <Switch>
        {Object.values(pages).map(({ pathname, component }) => (
          <Route path={pathname} key={pathname} component={component} exact />
        ))}
        <Route component={NotFound} />
      </Switch>
    </Layout.Content>
  );
};

export default Content;
