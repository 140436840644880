import React from 'react';

const GoogleMap = (props: React.IframeHTMLAttributes<HTMLIFrameElement>): JSX.Element => {
  return (
    <iframe
      width="100%"
      height="300"
      src="https://maps.google.com/maps?q=9%20rue%20Vall%C3%A9e%20-%2002400%20Ch%C3%A2teau-Thierry&t=&z=15&ie=UTF8&iwloc=&output=embed"
      frameBorder="0"
      scrolling="no"
      {...props}
    />
  );
};

export default GoogleMap;
