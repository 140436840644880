import { Title, WidthLimiter } from '@atoms';
import { ProsthesisCompleteJPG, SeniorJPG } from '@images/misc';
import { useTitle } from '@utils';
import { Col, Collapse, Row } from 'antd';
import React from 'react';

const ProsthesisComplete = (): JSX.Element => {
  useTitle('Prothèse amovible complète');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Prothèse amovible complète</Title>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '24px 0',
          }}
        >
          <img src={SeniorJPG} alt="Séniors" style={{ maxWidth: '100%' }} />
        </div>

        <p>
          Il n'y a pas d'âge pour prendre soin de soi, se débarrasser d'un ancien complexe et pouvoir profiter de sa
          retraite à pleines dents. Les techniques sont les mêmes que pour l'adulte plus jeune tout en acceptant
          certains compromis afin de moins solliciter les racines de ses dents.
        </p>
        <p>
          Dans certaines situations les dents se sont déplacées avec le temps et parfois même subitement. C'est bien
          souvent l'expression d'une atteinte parodontale qui entraine une fragilité osseuse en regard des racines
          dentaires (déchaussement). L'orthodontie permet de réaligner ces dents, une fois que la maladie parodontale
          est maîtrisée. Pour cela, l'orthodontiste travaille de pair avec le chirurgien-dentiste qui aura nettoyé les
          racines au préalable.
        </p>

        <Row align="middle" justify="space-between" gutter={[24, 24]}>
          <Col md={12} xs={24}>
            <p>
              La prothèse amovible complète est indiquée lorsqu'il y a une absence totale de dents. Souvent appelée
              "dentier", une prothèse amovible complète est une prothèse qui :
            </p>
            <ul>
              <li>permet de remplacer la totalité des dents du haut ou du bas.</li>
              <li>peut s'enlever facilement par le patient au quotidien.</li>
            </ul>

            <p>
              La prothèse complète tient en place dans la bouche par l'effet de ventouse (succion) créé par la salive
              qui s'accumule entre la prothèse et la gencive. Si la prothèse complète est réalisée sur la mâchoire
              supérieure, elle s'appuiera à la fois sur le gencive et le palais. Pour la mâchoire inférieure, elle
              s'appuie sur une bonne partie de la gencive. Elle sera cependant moins stable en bas en raison des
              mouvements de la langue et de la moindre surface de votre arcade ce qui explique l'utilisation de « colle
              » spéciale par les patients.
            </p>
          </Col>
          <Col md={12} xs={24}>
            <img src={ProsthesisCompleteJPG} alt="Prothèse complète" style={{ width: '100%' }} />
          </Col>
          <Col span={24}>
            <p>Pour réaliser une prothèse amovible complète, deux types de bases existent :</p>

            <ul>
              <li>
                la résine rose en acrylique : sa couleur imite la gencive naturelle et il s'agit du matériau de choix;
                c'est le cas le plus fréquent.
              </li>
              <li>
                la base métallique : la structure métallique est très fine, plus légère que la base acrylique et plus
                robuste. Elle sera cependant souvent envisagée pour renforcer une prothèse complète dont la base est en
                résine acrylique.
              </li>
            </ul>
            <p>
              Les dents artificielles montées sur la prothèse sont aujourd'hui principalement en résine (acrylique) de
              la couleur des dents naturelles. Les dents en céramique ou porcelaine sont beaucoup moins utilisées de nos
              jours.
            </p>
          </Col>

          <Col span={24}>
            <h3>Comment est réalisée une prothèse amovible complète ?</h3>
            <p>
              Comme expliqué ci-dessus, la prothèse complète permet de remplacer toutes les dents d'une arcade. En
              l'absence de traitement, avoir une alimentation correcte va devenir difficile et un déséquilibre
              nutritionnel peut apparaître. Des conséquences psychologiques sont également fréquentes en raison de la
              gêne et du handicap ressentis au moment de sourire. Une fois le plan de traitement validé, plusieurs
              rendez-vous seront nécessaires à votre dentiste pour réaliser votre prothèse complète. Votre traitement
              débute toujours par un examen de la bouche, des arcades et la prise de plusieurs empreintes pour obtenir
              toutes les informations nécessaires :
            </p>
            <ul>
              <li>forme des gencives, </li>
              <li>position des structures internes (freins, langue, voile du palais...),</li>
              <li>dimensions de la mâchoire,</li>
              <li>choix des dents (couleur, forme, matériau),</li>
              <li>matériau de base en fonction de la situation et des préférences du patient.</li>
            </ul>

            <p>
              La prothèse sera alors réalisée au laboratoire dans un délai de 7 à 15 jours. L'étape suivante consiste à
              l'essayage des cires de la prothèse par votre dentiste ; cet essayage permet de valider la fonction et
              l'esthétique de la prothèse et d'apporter les ajustements éventuellement nécessaires. Il est important de
              noter que plusieurs essayages peuvent être nécessaires. Les cires seront alors renvoyées au laboratoire
              pour la "cuisson" et la finition.
            </p>

            <p>
              Enfin, le rendez-vous de la pose est programmé. Votre dentiste va procéder à la mise en place et à
              l'ajustement de votre prothèse dentaire amovible. En la positionnant dans votre bouche, il va s'assurer
              qu'elle tient bien en place. Il vous fera réaliser certains tests pour être sûr qu'elle ne vous gêne pas
              et que vous pouvez ouvrir et fermer la bouche, manger, boire, sourire, rire, etc sans la faire tomber. La
              réhabilitation prothétique doit permettre que ces gestes soient réalisés de la manière la plus naturelle
              possible.
            </p>

            <p>
              Les contrôles et réglages d'une prothèse complète peut durer plusieurs semaines et votre dentiste
              programmera plusieurs séances de réglages / contrôle.
            </p>

            <Collapse>
              <Collapse.Panel
                header="Plus de précisions concernant la réalisation de prothèses complètes immédiates"
                key="1"
              >
                <p>
                  Si la situation clinique nécessite des extractions dentaires avant la réalisation d'une prothèse
                  complète, votre dentiste peut vous proposer la réalisation d'une prothèse complète immédiate pour vous
                  permettre de vous alimenter correctement tout de suite et de ne pas subir de préjudice esthétique
                  durant la période de cicatrisation.{' '}
                </p>
                <p>
                  La prothèse immédiate sera fabriquée avant les extractions en prenant des mesures de la mâchoire à
                  l'aide d'empreintes. Cette technique présente néanmoins un inconvénient non négligeable : elle doit
                  être ajustée plus fréquemment car la gencive et les os de la mâchoire changement fréquemment de forme
                  et de taille durant la période de cicatrisation. La prothèse peut alors devenir inconfortable, voir
                  blessante et mal tenir.
                </p>
                <p>
                  Suite aux extractions, un délai d'attente de 2 à 4 mois est recommandé par votre dentiste avant
                  d'envisager la réalisation des prothèses définitives.
                </p>
              </Collapse.Panel>
            </Collapse>
          </Col>

          <Col span={24}>
            <h3>Quelles sont les recommandations une fois la prothèse amovible complète posée ?</h3>
            <p>
              La période d'adaptation d'une prothèse amovible complète débute immédiatement après la pose ; au-delà des
              ajustements qu'il réalisera, votre dentiste recommande le respect des indications suivantes pour vous
              permettre d'accepter plus facilement votre traitement et d'assurer sa longévité dans le temps :
            </p>
            <ul>
              <li>
                assurer deux brossages par jour de vos gencives et de vos prothèses à l'aide d'une brosse à dents à
                poils souples ; la plaque dentaire et le tartre s'accumulent de la même façon que sur les dents
                naturelles.
              </li>
              <li>
                enlever votre prothèse tous les soirs, la nettoyer et la laisser tremper dans un récipient contenant une
                solution de trempage pour appareils en résine. Le fait de retirer la prothèse complète la nuit permet à
                votre gencive et à votre bouche de se reposer et de respirer. Le trempage permettra pour sa part de
                ramollir les dépôts présents sur l'appareil,
              </li>
              <li>
                être vigilant à la chute de votre appareil pour prévenir fissures et fractures ; au moment de son
                entretien, il est recommandé de remplir l'évier ou de positionner une serviette,
              </li>
              <li>
                éviter les aliments collants qui pourraient influer sur la stabilité de la prothèse et les aliments durs
                qui pourraient exercer une pression trop importante et l'endommager.
              </li>
            </ul>
            <p>
              Même si les dents ne sont plus présentes sur l'arcade, consulter votre dentiste régulièrement reste
              indispensable. Ces consultations régulières, une ou deux fois par an permettront à votre dentiste de :
            </p>

            <ul>
              <li>détecter des pathologies buccales,</li>
              <li>réaliser les ajustements nécessaires,</li>
              <li>contrôler l'état de la prothèse,</li>
              <li>polir la prothèse qui est soumise à de nombreuses agressions en bouche.</li>
            </ul>
          </Col>

          <Col span={24}>
            <h3>Quels sont les avantages et les limites des prothèses amovibles complètes ?</h3>
            <p>
              Le principal avantage de la pose d'une prothèse dentaire amovible complète est son coût peu élevé et la
              relative rapidité de la pose par rapport aux implants dentaires.{' '}
            </p>
            <p>
              Ce traitement dentaire, en l'absence d'extractions, ne nécessite aucune chirurgie et donc aucun temps de
              cicatrisation. A l'issue du traitement, le sourire est prévisible et conforme à vos attentes en raison de
              la possibilité de choisir la couleur et la forme données aux dents de la prothèse.{' '}
            </p>
            <p>
              De plus, la prothèse permet de retrouver des capacités masticatrices fonctionnelles, permettant de
              s'alimenter normalement et également de s'exprimer avec aisance. Elle peut être ajustée au besoin pour
              assurer au patient un confort constant.
            </p>
            <p>
              Parfois, les prothèses dentaires seront mal supportées. En effet, il peut arriver que de la nourriture se
              loge sous la prothèse et provoque un inconfort. Les prothèses amovibles réalisées à la mâchoire du bas
              seront moins stables car la gencive est plus petite, la quantité de salive au bord moins importante et car
              la langue effectue de nombreux mouvements.
            </p>
            <p>
              A la pose de la prothèse, une période d'adaptation est nécessaire et sa durée est plus longue s'il s'agit
              de la première réalisation d'une prothèse complète. Enfin, des blessures à la gencive peuvent survenir si
              la prothèse est moins ajustée nécessitant une séance de contrôle et réglages chez votre dentiste.
            </p>
          </Col>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default ProsthesisComplete;
