import React from 'react';
import { Layout, Row, Col } from 'antd';
import { WidthLimiter, GoogleMap } from '@atoms';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';
import { ADDRESS, MAIL, SCHEDULE, TEL_NUMBER } from '@config';
import { InstagramOutlined } from '@ant-design/icons';

const Footer = (): JSX.Element => {
  return (
    <Layout.Footer>
      <Container>
        <Row gutter={24} style={{ display: 'flex', overflow: 'hidden' }}>
          <Col md={8} xs={24}>
            <h2>
              <span>En savoir plus</span>
            </h2>
            <p>
              Pour toute question ou prise de rendez-vous, n'hésitez pas à nous contacter directement au{' '}
              <span style={{ whiteSpace: 'nowrap' }}>{TEL_NUMBER}</span>.
            </p>

            <p>
              <a
                href="https://instagram.com/cabinet_laly_rettel"
                target="_blank"
                rel="noreferrer"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <InstagramOutlined
                  rev={undefined}
                  style={{ fontSize: '28px', color: 'rgba(255,255,255,0.9)', marginRight: '8px' }}
                />
                <span style={{ color: 'rgba(255,255,255,0.8)' }}>Suivez-nous sur Instagram</span>
              </a>
            </p>
          </Col>

          <Col md={8} xs={24}>
            <h2>
              <span>Nous trouver</span>
            </h2>
            <GoogleMap style={{ borderRadius: '2px' }} />
          </Col>

          <Col md={8} xs={24}>
            <h2>
              <span>Informations</span>
            </h2>
            <ul>
              <TelNumber />
              <Mail />
              <Address />
              <Schedule />
            </ul>
          </Col>
        </Row>
      </Container>

      <CopyrightContainer>
        <span>Copyright © 2021. Tous droits réservés.</span>
      </CopyrightContainer>
    </Layout.Footer>
  );
};

export default Footer;

const TelNumber = () => (
  <li>
    <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: '8px' }} /> {TEL_NUMBER} (entre 9-12h et 14-18h)
  </li>
);
const Mail = () => (
  <li>
    <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }} />
    <a href={`mailto: ${MAIL}`}>{MAIL}</a>
  </li>
);
const Address = () => (
  <li>
    <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '8px' }} /> {ADDRESS}
  </li>
);
const Schedule = () => (
  <li>
    <FontAwesomeIcon icon={faClock} style={{ marginRight: '8px' }} />
    <p>
      <span>
        <code>Lun</code> : {SCHEDULE.mon}
      </span>
      <span>
        <code>Mar</code> : {SCHEDULE.tue}
      </span>
      <span>
        <code>Mer</code> : {SCHEDULE.wed}
      </span>
      <span>
        <code>Jeu</code> : {SCHEDULE.thu}
      </span>
      <span>
        <code>Ven</code> : {SCHEDULE.fri}
      </span>
    </p>
  </li>
);

const Container = styled(WidthLimiter)`
  & > div h2 {
    margin-bottom: 24px;
  }

  & > div h2 > span {
    color: white;
    padding-bottom: 8px;
    border-bottom: 3px solid #354d61;
  }

  & > div p {
    text-align: justify;
    color: rgba(255, 255, 255, 0.8);
  }

  & > div ul {
    list-style: none;
    list-style-position: inside;
    padding: 0;
    margin: 0;
    color: rgba(255, 255, 255, 0.8);
  }

  & > div ul > li {
    display: flex;
    vertical-align: top;
    align-items: top;
    justify-content: start;
  }

  & > div ul > li p {
    display: inline-block;
  }

  & > div ul > li p > span {
    display: block;
  }

  & > div ul > li a {
    color: white;
  }

  & > div ul > li svg {
    width: 14px !important;
    margin-top: 4px;
  }
`;

const CopyrightContainer = styled(WidthLimiter)`
  background: #8496a1;
  padding: 24px;
  margin-top: 12px;

  & > div {
    display: flex;
    justify-content: space-between;
  }

  & > div > span:first-child {
    opacity: 0.9;
  }
`;
