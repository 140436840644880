import React from 'react';

export function useTitle(newTitle: string): void {
  React.useEffect(() => {
    const prevTitle = document.title;
    document.title = newTitle + ' - ' + document.title;
    return () => {
      document.title = prevTitle;
    };
  });
}
