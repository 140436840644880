import { Title, WidthLimiter } from '@atoms';
import { useTitle } from '@utils';
import React from 'react';

const Zen = (): JSX.Element => {
  useTitle('Zen');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Zen</Title>
        <h3>Soins dentaires : sans douleurs ni stress</h3>
        <p>Il existe des concepts efficaces pour combattre le stress et la peur lors des soins dentaires.</p>
        <p>
          Des écrans fixés au plafond diffusent des images relaxantes de détente, et un casque audio couvre le bruit
          stressant de la roulette, tout en permettant au patient de maintenir la communication avec le praticien.
        </p>
        <p>Musique relaxante, images apaisantes, ambiance sophro-zen pour les adultes.</p>
        <p>Dessins animés ou series pour les ados.</p>
        <p>Les techniques actuelles permettent des soins dentaires SANS DOULEUR ET SANS STRESS</p>
      </WidthLimiter>
    </>
  );
};

export default Zen;
