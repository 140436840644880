import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Divider, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

const InfoCovidModal = (): JSX.Element => {
  const [isShown, setIsShown] = React.useState<boolean>(false);

  const toggleModal = React.useCallback(() => {
    setIsShown(!isShown);
  }, [isShown]);

  return (
    <>
      <Button
        type="text"
        danger
        icon={<FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '12px' }} />}
        onClick={toggleModal}
        style={{ fontWeight: 'bold' }}
      >
        Info COVID-19
      </Button>

      <StyledModal
        title={
          <h2 style={{ margin: 0 }}>
            COVID-19
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: '12px', color: '#ff4d4f' }} />
          </h2>
        }
        centered
        width={'75vw'}
        visible={isShown}
        onOk={toggleModal}
        onCancel={toggleModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Compris"
        destroyOnClose
      >
        <p style={{ display: 'flex', textAlign: 'center', fontSize: '1.05rem' }}>
          Nous avons pris toutes les mesures sanitaires nécessaires afin de vous recevoir dans les meilleures conditions
          de sécurité. Nous avons organisé un protocole de réception des patients afin de sécuriser votre arrivée et vos
          déplacements dans le cabinet.
        </p>

        <Divider />

        <h3>Mesures mises en place contre la propagation du virus Covid-19</h3>
        <p>
          Pour vous protéger et protéger l'équipe soignante, le CABINET DENTAIRE a adopté des mesures strictes afin de
          limiter et contenir la propagation du virus COVID-19. Les chirurgiens-dentistes sont parmi les professionnels
          de santé les mieux formés à l'hygiène et l'asepsie, et votre sécurité reste une priorité absolue.
        </p>

        <h3>Parcours du patient dans le cabinet</h3>
        <ul>
          <li>
            Régulation du flux patients à l'entrée et limitation de la capacité d'accueil de nos salles d'attente.
          </li>
          <li>
            Se présenter seul au cabinet (sauf enfant et personne à mobilité réduite) et à l'heure exacte de votre
            rendez-vous.
          </li>
          <li>A l'entrée du cabinet, le port du masque est obligatoire.</li>
          <li>Le patient est invité à déposer ses effets personnels dans un sac à usage unique.</li>
          <li>Friction au gel hydroalcoolique.</li>
          <li>Informations en salle d'attente, mesures de distanciations.</li>
          <li>Vitres de protections à l'accueil et dans les salles de soins.</li>
        </ul>

        <h3>Désinfection des locaux</h3>
        <ul>
          <li>
            Nous avons équipés nos locaux de purificateurs d'air médicaux permettant d'éliminer 99,95% des bactéries et
            virus présents dans l'air grâce à des filtres HEPA 13 de qualité médical, couplé à une lumière UVC
            stérilisant l'air ambiant.
          </li>
          <li> Bio nettoyage approfondi de l'ensemble des surfaces des salles de soins après chaque patient</li>
          <li>
            Désinfections tout au long de la journée des surfaces susceptibles d'être touchés (poignées de porte,
            comptoir, chaises, main courante, etc.)
          </li>
        </ul>

        <h3>Renforcement des équipements de protection individuelle (EPI) de l'équipe soignante</h3>

        <Alert
          type="warning"
          message={<span style={{ fontWeight: 'bold' }}>Attention</span>}
          description="Si vous avez été malade du COVID-19, si vous avez des symptômes du COVID-19 ou avez été en contact depuis moins de 15 jours d'une personne malade du COVID-19, veuillez le signaler AVANT votre rendez-vous.
Parce que la santé et la sécurité de nos patients, et de nos soignants sont notre priorité, nous comptons sur votre coopération à tous qui nous permettra, ensemble, de vous garantir un accueil et une prise en charge optimale."
        />
      </StyledModal>
    </>
  );
};

export default InfoCovidModal;

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    overflow: auto;
    height: 60vh;
    max-height: 60vh;
  }

  & p {
    text-align: justify;
  }

  & ul {
    list-style-type: '- ';
    list-style-position: inside;
    padding: 0;
  }
`;
