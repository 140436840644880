import { Title, WidthLimiter } from '@atoms';
import {
  AsepsieJPG,
  ChairJPG,
  ScreenJPG,
  SterilisationValidationJPG,
  TestBowieDickJPG,
  TestHelixJPG,
} from '@images/misc';
import { useTitle } from '@utils';
import { Col, Row } from 'antd';
import React from 'react';

const Equipment = (): JSX.Element => {
  useTitle('Notre matériel');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Notre matériel</Title>
        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Col md={12} xs={24}>
            <h3>Fauteuil conçu pour le client</h3>
            <h4>Un environnement de traitement sûr et propre</h4>
            <p>
              Lors de nos soins, la propreté de l'eau est une valeur centrale. Les solutions de conception pratiques de
              nos fauteuils facilitent le contrôle des infections car toutes les fonctions essentielles sont clairement
              organisées, intégrées, automatisées, expliquées et enregistrées. Un environnement sécurisé est garanti
              pour l'équipe soignante et les patients.
            </p>

            <h4>Des outils intelligents pour une dentisterie intelligente</h4>
            <p>
              Les rapides changements qui touchent le monde de la dentisterie impliquent de nouvelles demandes de la
              part des patients, notamment lorsqu'il s'agit d'améliorer l'efficacité et réduire le stress. Ce fauteuil
              nous apporte le confort nécessaire, au dentiste comme au patient.
            </p>
          </Col>
          <Col md={10} xs={24}>
            <img src={ChairJPG} alt="Fauteuil pour le patient" style={{ width: '100%' }} />
          </Col>
        </Row>

        <h3>Ecran au plafond : sans douleurs ni stress</h3>
        <Row gutter={[24, 24]} align="middle">
          <Col md={10} xs={24}>
            <img src={ScreenJPG} alt="Ecran au plafond : sans douleur ni stress" style={{ width: '100%' }} />
          </Col>
          <Col md={12} xs={24}>
            <p>Notre cabinet dentaire au coeur de Château-Thierry, vous aide à vaincre la peur du dentiste.</p>
            <p>
              Des écrans fixés au plafond diffusent un large choix d'images relaxante. Vous pouvez bien sur amener un
              casque audio qui couvre le bruit des soins.
            </p>
            <p>
              Les techniques actuelles permettent des soins dentaires <strong>sans douleur et sans stress</strong>.
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '36px', marginBottom: '36px' }}>
          <Col span={24}>
            <h3>La chaîne d'asepsie au cabinet dentaire</h3>
            <p>
              Les docteurs LALY et RETTEL attachent une importance toute particulière aux différents protocoles
              d'hygiène, d'asepsie et de traçabilité au cabinet dentaire.
            </p>
            <p>
              La maîtrise de la chaine d'asepsie passe par le contrôle de tous les vecteurs de transmissions potentiels
              :
            </p>
            <ul>
              <li>
                <strong>L'équipe médicale</strong> (le chirurgien dentiste, les assistantes et autre équipe
                d'intervention): une tenue professionnelle, un protocole d'hygiène ainsi qu'une gestuelle adaptée aux
                soins dentaires sont mis en place selon les règles générales d'asepsie.
              </li>
              <li>
                <strong>Le patient</strong> une rigueur dans l'installation et dans l'équipement de ce dernier lors des
                protocoles de chirurgie assure l'optimisation de l'asepsie lors du soin.
              </li>
              <li>
                <strong>Le lieu d'intervention</strong>, son environnement et le plateau technique utilisé. Outre la
                désinfection des salles de soin avec les produits normés et codifiés obligatoires, la chaîne de
                stérilisation des instruments est l'une des démarches la plus rigoureuse à effectuer au cabinet
                dentaire. Le bon respect des protocoles évite toute contamination croisée.
              </li>
            </ul>
            <figure>
              <img src={AsepsieJPG} alt="Matériel d'asepsie" style={{ maxWidth: '100%' }} />
              <figcaption>
                SNC30 : Châine d'asepsie automatisée. 1. Nettoyage / 2. Conditionnement / 3. Stérilisation
              </figcaption>
            </figure>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Col md={12} xs={24}>
            <h4>La chaîne de stérilisation : sa validation</h4>
            <p>
              Pour assurer un protocole de stérilisation optimal, les Drs LALY et RETTEL ont mis en place au cabinet
              dentaire un système d'organisation en Tubs and Trays du matériel dentaire. Ce concept permet d'optimiser
              l'hygiène et l'asepsie. Les cycles de stérilisation du matériel dentaire sont contrôlés et validés par
              différents tests normés tels que le test Bowie Dick et d'Hélix.
            </p>
          </Col>
          <Col md={10} xs={24}>
            <img src={SterilisationValidationJPG} alt="Sterilisation : validation" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} align="middle" style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Col md={6} xs={24}>
            <img src={TestBowieDickJPG} alt="Test Bowie Dick" style={{ width: '100%' }} />
          </Col>
          <Col md={{ offset: 2, span: 14 }} xs={24}>
            <h4>Le test Bowie Dick</h4>
            <p>
              Il valide le bon fonctionnement des autoclaves à vapeur d'eau de classe B, lors de la stérilisation
              d'objets poreux (compresses, blouses, champs opératoires ...).
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 24]} align="middle" style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Col md={14} xs={24}>
            <h4>Le test d'Hélix</h4>
            <p>
              Il permet de contrôler la capacité d'un autoclave de classe B à stériliser des objets creux de type A
              (cavités profondes et étroites)
            </p>
          </Col>
          <Col md={{ offset: 2, span: 4 }} xs={24}>
            <img src={TestHelixJPG} alt="Test Helix" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Col span={24}>
            <h4>La traçabilité au cabinet dentaire</h4>
            <p>
              Elle intervient dans la chaîne de stérilisation et lors de l'utilisation de dispositifs médicaux. Le but
              est de tenir un registre qui reprend la date de stérilisation, le numéro de charge, d'y apposer les
              indicateurs de stérilisation, et d'y lister les cassettes qui sont passées à l'autoclave.
            </p>
            <p>
              De plus, chaque cassette d'instruments stérilisés est étiquetée. On trouve sur l'étiquette le numéro de
              l'appareil de stérilisation, le numéro du cycle, la date de stérilisation et la date de péremption.
            </p>
            <p>
              Ainsi, une traçabilité rigoureuse permet de remonter toute une chaine de stérilisation et de vérifier que
              pour chaque patient la chaîne de stérilisation a été menée selon les normes. Le patient est alors soigné
              en toute sécurité.
            </p>
          </Col>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default Equipment;
