import React from 'react';
import { WelcomeSVG } from '@images/vectors';
import styled from 'styled-components';
import { Card, Col, Row } from 'antd';
import { SquareLink, Title, WidthLimiter } from '@atoms';
import Meta from 'antd/lib/card/Meta';
import { BachyPicture, LalyPicture, RettelPicture } from '@images/doctors';
import {
  BrushingTeethJPG,
  EmergencyJPG,
  EsthethicJPG,
  ImplantsJPG,
  KidsJPG,
  ParodontieJPG,
  ProthesisJPG,
} from '@images/links';
import pages from '@pages';
import BookRdvButton from 'components/molecules/BookRdvButton';
import { TeamPhoto } from '@images/team';
import { PhotoCabinet } from '@images/cabinet';
import TitleLink from 'components/atoms/TitleLink';

const Home = (): JSX.Element => {
  const squareLinks = [
    {
      title: 'Enfants',
      to: pages.kids.pathname,
      imgUrl: KidsJPG,
    },
    {
      title: 'Implants',
      to: pages.implantology.pathname,
      imgUrl: ImplantsJPG,
    },
    {
      title: 'Prothèses dentaires',
      to: pages.prosthesisComplete.pathname,
      imgUrl: ProthesisJPG,
    },
    {
      title: 'Esthétique dentaire',
      to: pages.bleaching.pathname,
      imgUrl: EsthethicJPG,
    },
    {
      title: 'Parodontie',
      to: pages.periodontology.pathname,
      imgUrl: ParodontieJPG,
    },
    {
      title: 'Brossage de dents',
      to: pages.brushingTeeth.pathname,
      imgUrl: BrushingTeethJPG,
    },
    {
      title: 'Urgence dentaire',
      to: pages.emergency.pathname,
      imgUrl: EmergencyJPG,
      danger: true,
    },
  ];

  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Row gutter={24} align="middle" justify="space-between">
          <Col md={12} xs={24}>
            <Title>Bienvenue au cabinet dentaire</Title>
            <p>
              Le <strong>cabinet dentaire</strong> des docteurs <strong>Céline LALY</strong> et{' '}
              <strong>Thibaut RETTEL</strong> est heureux de vous accueillir sur son site internet où vous trouverez
              toutes les informations nécessaires à votre future visite.
              <br />
              <br />
              Idéalement situé dans le lieu de naissance de Jean de la Fontaine, nous serons ravis de vous recevoir dans
              le cadre agréable et moderne offert par le{' '}
              <a href="https://www.polesantedelatour.fr/" rel="noreferrer" target="_blank">
                <strong>Pôle Santé de la Tour</strong>
              </a>{' '}
              de la ville de Château-Thierry.
              <br />
              <br />
              Nous avons à cœur d'être à l'écoute de chacun, et de créer une atmosphère chaleureuse où vous vous
              sentirez comme chez vous.
              <br />
              <br />
              Nous plaçons l'innovation et la technologie au cœur de notre pratique afin de vous proposer une solution
              optimisée et adaptée à chacun.
            </p>
          </Col>
          <Col md={12} xs={24}>
            <WelcomeContainer>
              <SvgContainer>
                <WelcomeSVG />
              </SvgContainer>
              <BookRdvButton />
            </WelcomeContainer>
          </Col>
        </Row>
      </WidthLimiter>

      <WidthLimiter style={{ padding: '24px 0', background: '#354d61', color: 'white' }}>
        <Row align="middle" justify="space-between" style={{ flexDirection: 'column' }}>
          <TitleLink to={pages.team.pathname}>Découvrez l'équipe</TitleLink>
          <Img src={TeamPhoto} alt="Photo d'équipe" />
        </Row>
      </WidthLimiter>

      <WidthLimiter style={{ padding: '24px 0', color: 'white' }}>
        <Row align="middle" justify="space-between" style={{ flexDirection: 'column' }}>
          <TitleLink to={pages.cabinet.pathname} style={{ color: '#354d61' }}>
            Découvrez le cabinet
          </TitleLink>
          <Img src={PhotoCabinet} alt="Photo du cabinet" />
        </Row>
      </WidthLimiter>

      <WidthLimiter style={{ padding: '24px 0', marginBottom: '24px' }}>
        <Row align="middle" justify="space-between" style={{ flexDirection: 'column' }}>
          <h2 style={{ paddingBottom: '8px', color: '#354d61' }}>Nos traitements</h2>
        </Row>
        <div style={{ display: 'flex', gap: '24px', justifyContent: 'center', flexWrap: 'wrap' }}>
          {squareLinks.map((props: { title: string; to: string; imgUrl: string }) => (
            <SquareLink {...props} key={props.title} />
          ))}
        </div>
      </WidthLimiter>

      <WidthLimiter style={{ padding: '24px 0', background: '#354d61', color: 'white' }}>
        <Row align="middle" justify="space-between" style={{ flexDirection: 'column' }}>
          <h2 style={{ paddingBottom: '8px', borderBottom: '3px solid #8496a1', color: 'white' }}>Vos dentistes</h2>
          <p style={{ textAlign: 'center', width: '66%' }}>
            Dans le but d'offrir un meilleur service, notre équipe met à jour ses connaissances régulièrement grâce à de
            la formation continue spécialisée. Notre force, l'expertise et le savoir-faire
          </p>
          <Row gutter={[24, 24]} justify="space-around" style={{ display: 'flex', flexDirection: 'initial' }}>
            <Col>
              <Card
                style={{ width: 280 }}
                cover={<img alt="Céline LALY" src={LalyPicture} />}
                actions={[
                  <a
                    href="https://www.doctolib.fr/dentiste/chateau-thierry/celine-laly-chateau-thierry"
                    target="_blank"
                    rel="noreferrer"
                    key="laly"
                    style={{ color: '#354d61' }}
                  >
                    Prendre rendez-vous
                  </a>,
                ]}
              >
                <Meta
                  title="Docteur Céline LALY"
                  description="Docteur en chirurgie dentaire, diplômée de la Faculté dentaire de Paris 7 - Garancière"
                />
              </Card>
            </Col>

            <Col>
              <Card
                style={{ width: 280 }}
                cover={<img alt="Thibaut RETTEL" src={RettelPicture} />}
                actions={[
                  <a
                    href="https://www.doctolib.fr/dentiste/chateau-thierry/thibaut-rettel"
                    target="_blank"
                    rel="noreferrer"
                    key="laly"
                    style={{ color: '#354d61' }}
                  >
                    Prendre rendez-vous
                  </a>,
                ]}
              >
                <Meta
                  title="Docteur Thibaut RETTEL"
                  description="Docteur en chirurgie dentaire, diplômé de la Faculté dentaire de Reims"
                />
              </Card>
            </Col>

            <Col>
              <Card
                style={{ width: 280, height: '100%' }}
                cover={<img alt="Louise BACHY" src={BachyPicture} />}
                actions={[
                  <a
                    href="https://www.doctolib.fr/adjoint-chirurgien-dentiste/chateau-thierry/louise-bachy"
                    target="_blank"
                    rel="noreferrer"
                    key="laly"
                    style={{ color: '#354d61' }}
                  >
                    Prendre rendez-vous
                  </a>,
                ]}
              >
                <Meta
                  title="Docteur Louise BACHY"
                  description="Adjoint chirurgien-dentiste, diplômée de la Faculté dentaire de Reims"
                />
              </Card>
            </Col>
          </Row>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default Home;

const Img = styled.img`
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  width: 75%;
  overflow: hidden;
  border-radius: 6px;
`;

const WelcomeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  & > h1 {
    font-size: 2rem;
  }

  & > button {
    margin-top: 2px;
    transform: scale(1.2) translateY(-38px);
    margin-bottom: -42px;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  width: 200px;
`;
