import React from 'react';

interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  primary?: boolean;
}

const Title = ({ primary = true, children, ...rest }: TitleProps): JSX.Element => {
  return (
    <h1 style={{ fontSize: '1.75rem', fontWeight: 'bold', marginBottom: '24px' }} {...rest}>
      <span
        style={{
          paddingBottom: '8px',
          borderBottom: `3px solid ${primary ? '#8496a1' : '#354d61'}`,
          ...(!primary && { color: `white` }),
        }}
      >
        {children}
      </span>
    </h1>
  );
};

export default Title;
