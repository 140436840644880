import { Title, WidthLimiter } from '@atoms';
import { CouronneJPG, InlayCore } from '@images/misc';
import { useTitle } from '@utils';
import { Col, Row } from 'antd';
import React from 'react';

const ProsthesisFixed = (): JSX.Element => {
  useTitle('Prothèse fixée');
  return (
    <WidthLimiter style={{ padding: '24px 0' }}>
      <Title>Prothèse fixée</Title>
      <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }}>
        <Col md={12} xs={24}>
          <h3>Les couronnes</h3>
          <p>
            Elles servent à reconstruire de façon durable une dent abîmée ou à protéger une dent (dévitalisée,
            inesthétique ou sensible)
          </p>
          <p>
            La dent à couronner est préparée pour recevoir la couronne (« taillée »). Une empreinte de cette préparation
            est alors réalisée et envoyée au prothésiste dentaire qui fabrique la couronne dans son laboratoire.
          </p>
          <p>
            La couronne est ensuite scellée sur la dent.
            <br />
            Une couronne peut se desceller. Garder la et retourner voir votre chirurgien-dentiste pour la resceller.
          </p>
        </Col>
        <Col md={10} xs={24}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={CouronneJPG} alt="Couronne" style={{ width: '90%' }} />
          </div>
        </Col>

        <Col span={24}>
          <h3>L'inlay core</h3>
          <p>
            L'inlay core est la solution qu'on utilise après avoir dévitalisé la dent. le chirurgien dentiste pose une
            tige dans le canal dentaire. Cette tige s'appelle pivot dentaire, inlay-core ou faux-moignon.
            <br />
            Cet inlay-core sera ensuite coifé d'une couronne.
          </p>
          <figure>
            <img src={InlayCore} alt="Reconstition par inlay-core" style={{ maxWidth: '100%' }} />
            <figcaption>Reconstitution par inlay-core</figcaption>
          </figure>
        </Col>
      </Row>
    </WidthLimiter>
  );
};

export default ProsthesisFixed;
