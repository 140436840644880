import { Title, WidthLimiter } from '@atoms';
import { KidsJPG } from '@images/misc';
import { useTitle } from '@utils';
import { Col, Row } from 'antd';
import React from 'react';

const Kids = (): JSX.Element => {
  useTitle('Enfants');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Enfants</Title>
        <Row align="middle" justify="space-between" gutter={24}>
          <Col md={12} xs={24}>
            <p>
              Notre philosophie est de tout mettre en oeuvre pour que l'expérience de votre enfant au sein du cabinet
              soit un moment éducatif et positif. Nous prenons en charge son éveil à la prévention bucco-dentaire mais
              aussi ses craintes et ses appréhensions.
            </p>

            <p>
              Pour la prévention, nous accompagnons nos soins de conseils alimentaires et d'hygiène et nous réalisons
              aussi des fluorations afin de diminuer le risque d'apparition de nouvelles caries ou les empêcher.
            </p>

            <p>
              La dentisterie pédiatrique, ou pédodontie, est la spécialité qui s'occupe des différents soins dentaires
              chez les enfants.
            </p>

            <p>
              Le pédodontiste pratique la prévention, afin de sensibiliser l'enfant et ses parents aux bons gestes de
              l'hygiène bucco-dentaire, mais aussi la détection précoce des caries et les soins dentaires.
            </p>

            <p>
              Les premières visites sont recommandées dès le plus jeune âge pour instaurer un climat de confiance et de
              coopération.
            </p>
          </Col>
          <Col md={12} xs={24}>
            <img src={KidsJPG} alt="Enfant content" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row style={{ marginTop: '24px' }}>
          <h3>En pratique :</h3>
          <p>
            La première visite est une visite de diagnostic et de mise en confiance. Un examen dentaire complet est
            réalisé avec des radios si nécessaire. A la fin de la séance le bilan est expliqué aux parents . Aucun soin,
            autre qu'un nettoyage prophylactique , n'est réalisé afin de permettre à votre enfant d'être le plus à
            l'aise possible.
          </p>

          <p>
            Si votre enfant nécessite des soins curatifs, tous est mis en œuvre pour que son expérience soit positive et
            que les soins soient réalisés dans les meilleures conditions. La communication avec du vocabulaire adapté à
            son âge , et l'explication des instruments (voir, entendre et sentir) installe une relation de confiance.
          </p>

          <p>
            Des visites tous les 6 mois sont essentielles pour garantir une bonne santé bucco-dentaire à votre enfant.
            Elles permettent de discuter des bonnes habitudes alimentaires et d'hygiène à adopter{' '}
          </p>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default Kids;
