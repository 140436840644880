import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

const BookRdvButton = (): JSX.Element => {
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>();

  React.useEffect(() => {
    return () => timeoutId && clearTimeout(timeoutId);
  }, [timeoutId]);

  const showDoctolib = React.useCallback(() => {
    setTimeoutId(setTimeout(() => setTimeoutId(undefined), 5000));
  }, []);

  const hideDoctolib = React.useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
    }
  }, [timeoutId]);

  return (
    <>
      <Button
        type="primary"
        shape="round"
        size="large"
        style={{ width: '196px', display: !timeoutId ? 'initial' : 'none' }}
        onClick={showDoctolib}
      >
        Prendre rendez-vous
      </Button>
      <ButtonStyled style={{ display: timeoutId ? 'initial' : 'none' }}>
        <div>
          <a
            onClick={hideDoctolib}
            href="https://www.doctolib.fr/dentiste/chateau-thierry/celine-laly-chateau-thierry"
            target="_blank"
            rel="noreferrer"
          >
            Céline L.
          </a>
          <a
            onClick={hideDoctolib}
            href="https://www.doctolib.fr/dentiste/chateau-thierry/thibaut-rettel"
            target="_blank"
            rel="noreferrer"
          >
            Thibaut R.
          </a>
          <a
            onClick={hideDoctolib}
            href="https://www.doctolib.fr/adjoint-chirurgien-dentiste/chateau-thierry/louise-bachy"
            target="_blank"
            rel="noreferrer"
          >
            Louise B.
          </a>
        </div>
      </ButtonStyled>
      <Doctolib style={{ color: timeoutId ? 'rgba(0, 0, 0, 0.4)' : 'transparent' }} />
      <NoteButton
        href="https://search.google.com/local/writereview?placeid=ChIJL-la0bnn6EcR7MeFgNJyBUs"
        target="_blank"
        type="text"
      >
        donner son avis
      </NoteButton>
    </>
  );
};

export default BookRdvButton;

const Doctolib = styled.div`
  &::after {
    content: 'doctolib';
    font-variant: small-caps;
    font-size: 1em;
    text-shadow: none;
  }
`;

const ButtonStyled = styled.button`
  color: #fff;
  border: none;
  background: #354d61;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  border-radius: 60px;
  width: 196px;
  height: 40px;
  font-size: 11px;
  padding: 0;
  overflow: hidden;

  & > div {
    height: 100%;
  }

  & > div > a {
    display: inline-flex;
    width: 33%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    background-color: transparent;
    transition: all 0.2s ease;
  }

  & > div > a:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }

  & > div > a:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  & > div > a:first-child {
    padding-left: 4px;
    border-right: 1px solid transparent;
  }

  & > div > a:nth-child(2) {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 4px;
  }

  & > div > a:last-child {
    padding-right: 4px;
    border-left: 1px solid transparent;
  }
`;

const NoteButton = styled(Button)`
  font-variant: small-caps;
  font-size: 1em;
  text-shadow: none;

  & > span {
    text-decoration: underline;
  }
`;
