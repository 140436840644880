import { Title, WidthLimiter } from '@atoms';
import { Facet1JPG, Facet2JPG, Facet3JPG } from '@images/misc';
import { useTitle } from '@utils';
import { Col, Row } from 'antd';
import React from 'react';

const Facet = (): JSX.Element => {
  useTitle('Restauration esthétique');
  return (
    <WidthLimiter style={{ padding: '24px 0' }}>
      <Title>La restauration esthétique des dents antérieures</Title>
      <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
        <Col span={24}>
          <p>
            La restauration des dents antérieures peut se faire par différentes techniques en fonction de leur degré de
            dégradation et de l'exigence esthétique du patient.
            <br />
            Le chirurgien dentiste pourra avoir recours à des techniques plus ou moins invasives, allant de la
            restauration par stratification au composite à la prothèse dentaire (facettes et couronnes dentaires en
            céramiques).
          </p>
        </Col>

        <Col md={12} xs={24}>
          <h3>La restauration par stratification au composite</h3>
          <p>
            Cette technique est utilisée principalement dans les délabrements de faibles et moyenne étendues, quand
            l'ancrage à la dent restante est suffisante pour effectuer un collage de résine composite de bonne qualité.
            <br />
            On l'utilise en cas de fracture dentaire coronaire suite à un traumatisme, à des caries ou en remplacement
            d'anciennes restaurations infiltrées ou délabrées. Elle permet de reconstruire la forme anatomique de la
            dent et de retrouver une homogénéité parfaite de la couleur de cette dernière grâce à la propriété
            biomimétique de la résine composite.
            <br />
            C'est une méthode peu invasive qui nécessite très peu de préparation dentaire.
          </p>
        </Col>

        <Col md={10} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <img src={Facet1JPG} alt="Stratification au composite" style={{ maxWidth: '60%' }} />
          </div>
        </Col>

        <Col md={12} xs={24}>
          <h3>Les facettes dentaires</h3>
          <p>
            Les facettes dentaires sont de fines pellicules de céramiques mesurant entre 0,2 et 0,8 mm d'épaisseur (une
            épaisseur supérieure sera utilisée dans des cas particuliers) permettant de traiter :
          </p>
          <ul>
            <li>
              <strong>les dyschromies</strong> : cela correspond à l'altération de la teintes des dents ne pouvant être
              traitée par un éclaircissement{' '}
            </li>
            <li>
              <strong>les dysmorphies</strong> : cela correspond à une altération de la forme des dents (dents conoïdes,
              diadèmes ou triangles noirs)
            </li>
            <li>
              <strong>les altérations par perte de tissu dentaire</strong> trop importantes pour être restaurées par une
              stratification de composite (fracture coronaire étendue, usure des bords libres, phénomène d'érosion ou de
              malformation congénitale acquise){' '}
            </li>
            <li>
              <strong>les malpositions dentaires</strong>
            </li>
          </ul>
          <p>
            Cette technique est peu invasive mais requiert tout de même une légère préparation de l'émail pour optimiser
            sa bio-intégration et son adhésion.
          </p>
        </Col>

        <Col md={10} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <img src={Facet2JPG} alt="Facettes dentaires" style={{ maxWidth: '70%' }} />
          </div>
        </Col>

        <Col md={12} xs={24}>
          <h3>Les prothèses céramo-céramiques</h3>
          <p>
            Les couronnes céramo-céramiques sont des prothèses exemptes de métal, permettant de reconstituer et de
            protéger des dents fortement délabrées.
            <br />
            Leur biocompatibilité exceptionnelle avec la gencive ainsi que leur rendu esthétique en font un choix de
            qualité pour une bonne intégration dans le secteur antérieur, et permettent la réhabilitation du sourire de
            manière optimale.
            <br />
            Elles remplacent donc à juste titre les prothèses céramo-métalliques selon les données acquises de la
            science.
          </p>
        </Col>

        <Col md={10} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <img src={Facet3JPG} alt="Prothèses céramo-céramiques" style={{ maxWidth: '70%' }} />
          </div>
        </Col>
      </Row>
    </WidthLimiter>
  );
};

export default Facet;
