import { Title, WidthLimiter } from '@atoms';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConeBeamJPG, RadioAlveolaireJPG, RadioJPG } from '@images/misc';
import { useTitle } from '@utils';
import { Col, Row } from 'antd';
import React from 'react';

const Radios = (): JSX.Element => {
  useTitle('Radios');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Radios</Title>
        <p>
          Notre unité radiologique couvre l'intégralité de la dentition, ce qui en fait une option idéale pour
          l'imagerie dentaire 3D de l'arcade complète.
        </p>
        <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
          <Col span={24}>
            <h3>Imagerie à dose ultra faible</h3>
            <p>
              Nous proposons une solution pour acquérir des images CBCT à des doses patientes comparables, voire
              inférieures à celles des images panoramiques.
            </p>
            <h4>Le jour de l'examen</h4>
            <p>Quelques informations pratiques pour le jour de votre examen.</p>

            <p>Pièces administratives à fournir :</p>
            <ul>
              <li>carte vitale,</li>
              <li>prescription de votre médecin ou carton de rendez-vous </li>
              de notre centre,
              <li>attestation C.M.U ou A.M.E</li>
              <li>
                pour une évaluation performante, merci de communiquer d'éventuels documents radiologiques antérieurs.
              </li>
            </ul>

            <p>Recommandations particulières :</p>
            <ul>
              <li>retirer les bijoux, les piercings,</li>
              <li>retirer les appareils dentaires amovibles lors de l'examen.</li>
              <li>nous prévenir en cas de grossesse avérée ou probable.</li>
            </ul>

            <p>
              Certains examens nécessitent un important travail de reconstruction informatique et d'interprétation
              médicale par le radiologue. Ils ne peuvent donc pas être remis juste après leur réalisation.
            </p>
          </Col>

          <Col span={24}>
            <h3>Radiographie panoramique</h3>
            <p>
              Il demeure le cliché de première intention permettant une évaluation globale des maxillaires et des dents.
              Mais aussi de l'articulation temporo-mandibulaire, sur un seul film pour une faible exposition aux rayons
              X.{' '}
            </p>
            <p>
              Réalisable dès 6 ans, cet examen extrêmement courant, constitue une base de travail précise pour
              l'ensemble des disciplines dentaires.
            </p>

            <figure>
              <img src={RadioJPG} alt="Image de radio panoramique" style={{ maxWidth: '100%' }} />
              <figcaption>Image de radio panoramique</figcaption>
            </figure>
          </Col>

          <Col span={24}>
            <h3>Le Cone Beam</h3>
            <p>
              Le Cone Beam (ou tomographie volumique à faisceaux coniques) est Plus précis qu'une radiographie
              panoramique et moins irradiante que le scanner classique, le scanner Cone Beam permet de réaliser des
              clichés des tissus minéralisés du crâne (os, cartilages et dents) dans tous les plans de l'espace, offrant
              la possibilité d'une reconstruction informatique en 3 dimensions.{' '}
            </p>
            <p>
              Les appareils Cone Beam de dernière génération présentent une qualité d'image équivalente, voire
              supérieure, au scanner, pour l'étude des dents ou des sinus de la face.
            </p>

            <figure>
              <img src={ConeBeamJPG} alt="Image prise - Cone Beam" style={{ maxWidth: '100%' }} />
              <figcaption>Radio - Cone Beam</figcaption>
            </figure>
          </Col>

          <Col md={12} xs={24}>
            <h3>Radios rétro-alvéolaires et bilan long cône</h3>
            <p>
              La radio rétro-alvéolaire est une radio intra-buccale de petite taille qui permet d'observer 2 ou 3 dents
              contigües.
            </p>
            <p>
              Cet examen radiologique complète l'examen clinique pour détecter et analyser les caries et infections, les
              racines dentaires, la tenue de traitements déjà réalisés ou encore d'éventuelles pathologies
              sous-jacentes.
            </p>
            <p>
              Le bilan long cône est la réalisation de 17 radio rétro-alvéolaires pour enregistrer et analyser toutes
              les dents présentes en bouche.
            </p>
          </Col>
          <Col md={12} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={RadioAlveolaireJPG} alt="Radio rétro-alvéolaire" style={{}} />
          </Col>

          <Col span={24}>
            <h3>Indications</h3>

            <p>
              <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
              <strong>Implantologie dentaire</strong> : du fait de la précision de ses mesures, le Cone Beam est indiqué
              pour marquer la position d'éléments anatomiques, par exemple dans le cas d'extraction chirurgicale de
              troisièmes molaires inférieures incluses. La modélisation en trois dimensions permet également
              l'élaboration de guides chirurgicaux. Assisté par informatique, le praticien va ainsi créer avec
              exactitude un dispositif permettant de fixer la prothèse dentaire en tenant compte de l'anatomie osseuse
              du patient, (par exemple dans le cas d'une masse osseuse réduite).
            </p>
            <p>
              <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
              <strong>Endodontie</strong> : lorsque la radiologie conventionnelle n'apporte pas de résultats suffisants,
              le Cone Beam permet un bilan apical (système canalaire de la dent) avant un traitement chirurgical,
              l'analyse de l'anatomie endodontique, la recherche de pathologies endodontiques complexes.
            </p>
            <p>
              <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
              <strong>Chirurgie buccale</strong> : est utilisée pour repérer des kystes ou des tumeurs bénignes, pour
              des bilans infectieux, des dents de sagesse mal placées, des dents incluses et des troubles de l'éruption
              dentaire.
            </p>
            <p>
              <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
              <strong>Chirurgie maxillo-faciale, traumatologie</strong> : pour identifier des fractures, des lésions
              arthrosiques de l'ATM, des dysmorphoses faciales (par exemple menton trop en avant -prognathisme- ou trop
              en arrière -rétrognathisme) lorsqu'une exploration des tissus mous n'est pas requise et lorsque le niveau
              d'irradiation est moindre qu'avec un scanner classique.
            </p>
            <p>
              <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
              <strong>Orthopédie dento-faciale</strong> : pour le diagnostic et l'analyse des structures osseuses de la
              face
            </p>
          </Col>
          <Col span={24}>
            <h3>Nos conseils</h3>
            <ul>
              <li>
                Les performances techniques et dosimétriques (dose de rayons absorbés) de l'examen Cone Beam peuvent
                permettre de le proposer dans des indications cliniques bien sélectionnées et quand l'étude des tissus
                mous n'est pas requise.
              </li>
              <li>
                Des principes fondamentaux de justification et d'optimisation d'utilisation doivent être respectés. Le
                Cone Beam ne peut se substituer aux autres examens d'imagerie s'il n'améliore pas la prise en charge des
                patients et si son intérêt dosimétrique n'est pas démontré.
              </li>
            </ul>
          </Col>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default Radios;
