/* eslint-disable @typescript-eslint/no-var-requires */
import { FunctionComponent } from 'react';

const pages: Record<string, IPage> = {
  home: { pathname: '/', component: require('./Home').default },
  accessPlan: { pathname: '/plan-acces', component: require('./AccessPlan').default },

  team: { pathname: '/equipe', component: require('./Team').default },

  // Cabinet
  cabinet: { pathname: '/notre-cabinet', component: require('./Cabinet/OurCabinet').default },
  equipment: { pathname: '/notre-equipement', component: require('./Cabinet/Equipment').default },
  radios: { pathname: '/radios', component: require('./Cabinet/Radios').default },
  tubsAndTrays: { pathname: '/tubs-and-trays', component: require('./Cabinet/TubsAndTrays').default },
  stress: { pathname: '/gestion-stress', component: require('./Cabinet/Stress').default },
  anecdotes: { pathname: '/anecdotes', component: require('./Cabinet/Anecdotes').default },

  // Treatments
  odontology: { pathname: '/odontologie', component: require('./Treatments/Odontology').default },
  bleaching: { pathname: '/blanchiment', component: require('./Treatments/Bleaching').default },
  facet: { pathname: '/facette', component: require('./Treatments/Facet').default },
  implantology: { pathname: '/implantologie', component: require('./Treatments/Implantology').default },
  periodontology: { pathname: '/parodontologie', component: require('./Treatments/Periodontology').default },
  prosthesisComplete: {
    pathname: '/prothese-complete',
    component: require('./Treatments/ProsthesisComplete').default,
  },
  prosthesisFixed: {
    pathname: '/prothese-fixee',
    component: require('./Treatments/ProthesisFixed').default,
  },
  kids: { pathname: '/enfants', component: require('./Treatments/Kids').default },
  seniors: { pathname: '/seniors', component: require('./Treatments/Seniors').default },
  prf: { pathname: '/prf', component: require('./Treatments/PRF').default },

  emergency: { pathname: '/urgence', component: require('./Emergency').default },
  contact: { pathname: '/contact', component: require('./Contact').default },
  search: { pathname: '/recherche', component: require('./Search').default },

  // Others
  brushingTeeth: { pathname: '/hygiene-dentaire', component: require('./BrushingTeeth').default },
};

interface IPage {
  pathname: string;
  component: FunctionComponent;
}

export { default as NotFound } from './NotFound';
export default pages;
