import { Title, WidthLimiter } from '@atoms';
import { OdontonologyJPG, OdontonologyExamplesJPG } from '@images/misc';
import { useTitle } from '@utils';
import { Col, Row } from 'antd';
import React from 'react';

const Odontology = (): JSX.Element => {
  useTitle('Odontologie conservatrice');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>L'odontologie conservatrice</Title>
        <Row align="middle" justify="space-between" gutter={24}>
          <Col md={12} xs={24}>
            <p>
              La dentisterie conservatrice consiste à la conservation des dents en bouche. Elle implique le diagnostic
              et le traitement des caries dentaires et comprend diverses techniques et procédures.
            </p>

            <p>
              Le rôle principal du dentiste conservateur est le diagnostic et le traitement de la plaque dentaire, des
              infections et des caries. Afin de restaurer les dents dans leur fonction et leur apparence de manière
              agréable en préservant la structure dentaire.
            </p>

            <p>
              Un minimum de structure dentaire saine est enlevé au cours du processus de restauration, ce qui reste un
              objectif dentaire souhaitable. Le traitement du canal est l'un des principaux traitements couramment
              utilisés pour maintenir la santé des dents.
            </p>

            <p>
              Dans cette méthode, on pratique une opération au cours de laquelle la plus grande partie de la zone
              affectée est enlevée puis obturée, ce qui permet de préveni une infection.
            </p>
          </Col>
          <Col md={12} xs={24}>
            <img src={OdontonologyJPG} alt="Exemple des effets de l'odontologie" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
          <Col span={24}>
            <h3>Les inlays/onlays/overlays :</h3>
            <figure>
              <img src={OdontonologyExamplesJPG} alt="Exemples d'inlays/onlays/overlays" style={{ maxWidth: '100%' }} />
              <figcaption>Exemples d'inlays/onlays/overlays</figcaption>
            </figure>

            <p>
              <strong>Lorsqu'une carie est très étendue</strong> et qu'une réparation classique par une résine composite
              réalisée directement en bouche est impossible, il est préférable de réaliser au laboratoire de prothèse
              dentaire un inlay ou un onlay. Ces incrustations dentaires remplacent avantageusement les plombages (qui
              ne sont plus utilisés dans notre cabinet pour des raisons biologiques et esthétiques) et permettent de
              restaurer la dent très esthétiquement. Elles offrent en outre une étanchéité et une durée de vie plus
              importantes.
            </p>

            <p>
              <strong>Les inlays</strong> sont des pièces en céramique (ou en résine composite) collées qui permettent
              de restaurer à minima la dent délabrée en conservant un maximum la substance dentaire résiduelle.
            </p>

            <p>
              <strong>Les onlays</strong> sont des pièces en céramique (ou en résine composite) collées qui restaurent
              une cavité et une cupside dentaire.
            </p>

            <p>
              <strong>Les overlays</strong> recouvrent totalement la surface de la dent.
            </p>
          </Col>

          <Col span={24}>
            <h3>Technique de réalisation :</h3>
            <h4>Première séance</h4>
            <p>La dent est préparée en nettoyant la carie et en taillant une cavité qui accueillera :</p>
            <ul>
              <li>L'inlay lorsque la cavité se trouve à l'intérieur des parois de la dent</li>
              <li>L'onlay lorsque la cavité englobe une ou plusieurs parois.</li>
              <li>L'overlay lorsque la cavité reconstitue toute la surface masticatrice de la dent.</li>
            </ul>
            <p>
              Une empreinte dentaire est ensuite réalisée et envoyée au laboratoire avec les références de teinte.
              L'inlay (ou l'onlay) sera fabriqué en un monobloc qui s'insère parfaitement dans la cavité comme un
              morceau de puzzle.
            </p>

            <h4>Deuxième séance</h4>
            <p>
              <strong>L'inlay est posé et collé</strong> dans la cavité de la dent. Contrairement aux obturations
              classiques, les inlays/onlays n'exercent pas de pressions sur les parois dentaires restantes (risque de
              fracture). L'adhésion chimique aux parois dentaires consolide l'ensemble et assure une meilleure
              étanchéité qui bloque toute infiltration bactérienne.
            </p>
          </Col>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default Odontology;
