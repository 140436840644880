import { Title, WidthLimiter } from '@atoms';
import React from 'react';
import { Col, Row } from 'antd';
import { useTitle } from '@utils';
import { Image } from 'antd';
import { Bees, CohesionTeam, Formation2, FormationPhoto, TreeOfLife } from '@images/misc';

const Anecdotes = (): JSX.Element => {
  useTitle('Anecdotes');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Row gutter={24} align="middle" justify="space-between">
          <Col md={12} xs={24}>
            <Title>Anecdotes</Title>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle" className="revert">
          <Col md={10} xs={24}>
            <Image src={TreeOfLife} alt="Les arbres de vie" style={{ width: '100%' }} />
          </Col>
          <Col md={12} xs={24}>
            <h3>Les arbres de vie</h3>
            <p>
              Vous trouverez dans chaque pièce du cabinet dentaire un arbre de vie. C’est notre fil conducteur.
              Symbolisant la paix et l’harmonie, l’arbre de vie permet de se recentrer sur soi et d’apaiser les esprits.
            </p>
            <p>
              Lors de la création de notre cabinet, aboutissement personnel après des années de travail, nous avons
              voulu partager avec nos patients notre vision de la dentisterie. La santé est intimement liée au bien être
              mental, psychique et moral. Beaucoup de patient craignent les soins dentaires. Aussi nous voulions que
              chaque personne puisse trouver chez nous la force de s’exprimer et de se faire soigner sans jugement
              aucun, malgré ses peurs et ses doutes.
            </p>
            <p>
              Les arbres de vie disposés dans chaque pièce aideront à harmoniser le corps et l’esprit pour tendre vers
              une complète sérénité.
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
          <Col md={12} xs={24}>
            <h3>Cohésion d'équipe</h3>
            <p>
              En plus d’être de bons collègues de travail, l’équipe est une véritable famille. Amis ou parents dans la
              vie, ces liens nous apportent force et cohésion. Notre cabinet a été pensé, créé, peint, agencé par tous
              les membres de l’équipe et leur conjoint.
            </p>
            <p>
              Un dentiste un pinceau à la main vous y croyez-vous ? Photos à l’appui ! Notre cabinet est né en quelques
              mois seulement. Rebondissement inattendu dans la vie de chacun, il nous comble aujourd'hui et est devenu
              pour tous un deuxième foyer.
            </p>
          </Col>
          <Col md={10} xs={24}>
            <Image src={CohesionTeam} alt="Photo cohésion d'équipe" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle" className="revert">
          <Col md={10} xs={24}>
            <Image src={FormationPhoto} alt="Hypnose médicale" style={{ width: '100%' }} />
          </Col>
          <Col md={12} xs={24}>
            <h3>Hypnose médicale</h3>
            <p>Pour vous aider à affronter les soins dentaires, nous nous formons pour certains à l’hypnose médical.</p>
            <p>
              Petit moment de détente avant la séance, sans pratiquer pour autant l’hypnose de rue, nous essayons de
              vous apaiser au maximum en vous faisant voyager.
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
          <Col md={12} xs={24}>
            <h3>Les abeilles du cabinet</h3>
            <p>
              Le saviez-vous ? C’est le surnom donné aux assistantes dentaires en France. Véritable essaim indispensable
              au bon fonctionnement d’un cabinet dentaire, elles sont multitâches et bourdonnent partout dans les locaux
              pour assurer à leurs dentistes des conditions de travail idéales.{' '}
            </p>
          </Col>
          <Col md={10} xs={24}>
            <Image src={Bees} alt="Abeilles du cabinet" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle" className="revert">
          <Col md={10} xs={24}>
            <Image src={Formation2} alt="Formations régulières" style={{ width: '100%' }} />
          </Col>
          <Col md={12} xs={24}>
            <h3>Formations régulières</h3>
            <p>
              Parce que nous avons soif de connaissances, mais aussi pour que ces connaissances restent en conformité
              avec les dernières données acquises de la sciences, vos dentistes et leurs assistantes suivent tous les
              ans plusieurs formations.
            </p>
            <p>
              Surfer sur la vague est plus que primordial, et pas seulement pendant les vacances… Mais cela n’empêche
              pas de passer de bons moments, l’utile et l’agréable sont de mise. Photos envoyés
            </p>
          </Col>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default Anecdotes;
