import { Button, ButtonProps } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface TitleLinkProps extends ButtonProps {
  to: string;
}

const TitleLink = ({ to, children, ...rest }: TitleLinkProps): JSX.Element => {
  const { push } = useHistory();
  const onClick = React.useCallback(() => {
    window.scrollTo(0, 0);
    push(to);
  }, [push, to]);

  return (
    <Container type="text" onClick={onClick} {...rest}>
      {children}
    </Container>
  );
};

export default TitleLink;

const Container = styled(Button)`
  color: white;
  font-size: 1.5em;
  margin-bottom: 12px;
  height: auto;

  & > span {
    text-decoration: underline;
  }

  &:hover {
    color: #fcfdfd;
  }
`;
