import { Title, WidthLimiter } from '@atoms';
import { ImplantologyJPG } from '@images/misc';
import { useTitle } from '@utils';
import { Col, Row } from 'antd';
import React from 'react';

const Implantology = (): JSX.Element => {
  useTitle('Implantologie');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Implantologie</Title>
        <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
          <Col span={24}>
            <h3>Prothèse fixe sur implant</h3>
            <p>
              Des couronnes et des bridges peuvent être réalisés sur des implants. Une ou plusieurs empreintes sont
              effectuées et la prothèse est ensuite confectionnée par un prothésiste dentaire avant d'être fixée aux
              implants.
            </p>
            <p>Il existe deux catégories de prothèses dentaires fixes sur implant :</p>
            <ul>
              <li>
                La prothèse scellée. Elle nécessite la mise en place d'un élément intermédiaire appelé pilier ou "faux
                moignon", vissé sur l'implant et sur lequel sera scellée la prothèse en céramique.
              </li>
              <li>
                La prothèse vissée. Au centre de la prothèse il y a un accès pour la vis qui va permettre de
                "transvisser" la dent sur le pas de vis de l'implant. Cet accès sera ensuite comblé pour le dissimuler
                et le protéger.
              </li>
            </ul>
            <p>
              Cette couronne monobloc transvissée est fixée sur l'implant uniquement par vissage, sans utilisation de
              ciment.
            </p>

            <p>Ces deux concepts ont chacun leurs indications cliniques, leurs avantages et leurs limites.</p>

            <figure>
              <img src={ImplantologyJPG} alt="Exemples d'inlays/onlays/overlays" style={{ maxWidth: '100%' }} />
              <figcaption>
                1. Mise en place du pilier / 2. Mise en place de la couronne / 3. Prothèse terminée
              </figcaption>
            </figure>
          </Col>
          <Col span={24}>
            <h3>Prothèse amovible sur implants</h3>

            <p>
              Les prothèses amovibles, qu'elles soient partielles ou complètes peuvent être stabilisées par des
              implants. Les implants sont mis en place avant la réalisation de la prothèse ou sous une prothèse déjà
              existante. La prothèse vient se fixer sur le ou les implants par des moyens d'attache qui se présentent
              souvent sous la forme de petits boutons-pression.
            </p>
            <p>
              Dans certains cas, stabiliser une prothèse amovible partielle permet de supprimer des crochets qui
              seraient inesthétiques.
            </p>
          </Col>

          <Col span={24}>
            <h3>Délais de réalisation de la prothèse après la mise en place des implants</h3>
            <p>
              De façon générale, on attend qu'un implant soit bien intégré à l'os dans lequel il a été placé pour
              réaliser la prothèse (entre deux et six mois). Avec l'évolution des implants, ce délai tend à diminuer ;
              il est possible, parfois, de réaliser une prothèse transitoire immédiatement après la pose de l'implant.
              Ceci permet, surtout pour les dents antérieures, d'avoir une prothèse transitoire fixe tout à fait
              esthétique.
            </p>
          </Col>

          <Col span={24}>
            <h3>Nos conseils</h3>
            <ul>
              <li>
                Les prothèses implantaires doivent faire l'objet d'une surveillance régulière au même titre que les
                prothèses sur dents naturelles.
              </li>

              <li>
                Une hygiène dentaire rigoureuse permet de limiter le risque d'infection qui entraînerait un
                déchaussement au niveau des implants, de la même manière qu'au niveau des dents.
              </li>
            </ul>
          </Col>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default Implantology;
