import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Layout } from 'antd';
import { Header, Content, Footer } from '@templates';
import './index.css';

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Layout>
      <Header />
      <Content />
      <Footer />
    </Layout>
  </Router>,
  document.getElementById('application'),
);
