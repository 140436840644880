import { Card } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

interface SquareLinkProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  to: string;
  imgUrl: string;
  danger?: boolean;
  disabled?: boolean;
}

const SquareLink = ({ to, title, imgUrl, danger, disabled, ...rest }: SquareLinkProps): JSX.Element => {
  const { push } = useHistory();
  const onClick = React.useCallback(() => {
    window.scrollTo(0, 0);
    push(to);
  }, [push, to]);

  return (
    <StyledCard
      hoverable
      onClick={onClick}
      $imgUrl={imgUrl}
      $danger={danger}
      $disabled={disabled}
      bordered={false}
      {...rest}
    >
      <span>{title}</span>
    </StyledCard>
  );
};

export default SquareLink;

interface StyledCardProps {
  $imgUrl: string;
  $danger?: boolean;
  $disabled?: boolean;
}

const StyledCard = styled(Card)<StyledCardProps>`
  @media screen and (max-width: 480px) {
    min-width: 156px !important;
    min-height: 156px !important;
    width: 156px !important;
    height: 156px !important ;

    & > .ant-card-body > span {
      font-size: 1.25rem !important;
    }
  }

  min-width: 200px;
  min-height: 200px;
  width: 14vw;
  height: 14vw;
  color: ${({ $danger }) => `${$danger ? '#ff4d4f' : 'black'}`};
  border-radius: 6px;
  background: ${({ $imgUrl }) => `url(${$imgUrl}) no-repeat center center`};
  background-size: cover;
  pointer-events: ${({ $disabled }) => `${$disabled ? 'none' : 'auto'}`};
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  & > .ant-card-body {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ $disabled }) => `${$disabled ? 'rgba(200, 200, 200, 0.7)' : 'rgba(255, 255, 255, 0.35)'}`};
    text-align: center;
    padding: 24px;
    transition: all 0.2s ease;

    border: ${({ $danger }) => `1px solid ${$danger ? '#ff4d4f' : 'transparent'}`};
    border-radius: 6px;
  }

  & > .ant-card-body > span {
    font-size: 1.5rem;
    transition: all 0.2s ease;
    font-family: 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
    text-shadow: ${({ $danger }) => `${$danger ? '#ff4d4f' : 'black'} 0 0 1px, white 0 0 2px`};
  }

  &:hover {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }

  &:hover > .ant-card-body {
    background: rgba(255, 255, 255, 0.7);
  }

  &:hover > .ant-card-body > span {
    transform: scale(1.1);
  }
`;
