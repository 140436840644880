import { WidthLimiter } from '@atoms';
import React from 'react';

const Test = (): JSX.Element => {
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '40vh',
            textAlign: 'center',
          }}
        >
          <span style={{ fontSize: '32px', maxWidth: '600px' }}>
            <span
              style={{ display: 'block', textAlign: 'left', fontWeight: 'bold', textIndent: '12px', fontSize: '2em' }}
            >
              Oups...
            </span>{' '}
            Il se pourrait que vous vous soyez perdus car cette page n'existe pas !
          </span>
        </div>
      </WidthLimiter>
    </>
  );
};

export default Test;
