import { Title, WidthLimiter } from '@atoms';
import React from 'react';
import { Col, Row } from 'antd';
import { useTitle } from '@utils';
import { styled } from 'styled-components';
import { Caillot, Centrifugation, TakeBlood } from '@images/misc';

const PRF = (): JSX.Element => {
  useTitle('Fibrine riche en plaquttes (PRF)');
  return (
    <WidthLimiter style={{ padding: '24px 0' }}>
      <Title>Le PRF (Fribrine riche en plaquettes)</Title>
      <p>
        L’utilisation du PRF en chirurgie dentaire a été décrite par le Dr Choukroun en 2000. Elle permet de
        potentialiser les chirurgies au cabinet dentaire et a de multiples indications. Notre cabinet dentaire s’est
        formé à cette technique et vous explique en quelques mots en quoi cela consiste.
      </p>

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <h3>Comment et pourquoi le PRF ?</h3>
          <p>
            Votre sang est prélevé juste avant l’intervention et centrifugé immédiatement. Votre dentiste en récupérera
            un petit concentré: un caillot de fibrine, très riche en facteurs de croissance.
          </p>

          <ImagesStepsContainer>
            <figure>
              <img src={TakeBlood} />
              <figcaption>1. Prélèvement</figcaption>
            </figure>

            <figure>
              <img src={Centrifugation} />
              <figcaption>2. Centriguration</figcaption>
            </figure>

            <figure>
              <img src={Caillot} />
              <figcaption>3. Caillot de figurine</figcaption>
            </figure>
          </ImagesStepsContainer>

          <h3>A quoi cela sert-il ?</h3>
          <p>
            Ces caillots vont protéger vos plaies, diminuer les douleurs post opératoires, favoriser la cicatrisation,
            diminuer le risque d’infections et accélérer la régénération osseuse.
          </p>

          <h3>Rien ne peut remplacer votre sang ! </h3>
          <p>
            Il existe des biomatériaux (d’origine humaine, animale ou synthétique), qui seront peut être utilisés
            associés à votre sang. Mais seul votre sang peut vous apporter de manière aussi efficace et naturelle tous
            les facteurs de croissance nécessaires à votre cicatrisation. Il n’y a de plus, aucun risque de rejet, car
            il s’agit de votre propre sang.
          </p>

          <p>
            Cette technique naturelle vous impose le désagrément d’une prise de sang mais facilitera grandement votre
            cicatrisation, vous permettant de gagner de l’os et de la gencive dans les sites résorbés.{' '}
          </p>

          <h3>Prélève t-on beaucoup de sang ? </h3>
          <p>
            Non, la quantité de sang prélevée est similaire à celle d’analyses sanguines. Il n’y a pas plus de fatigue à
            craindre.
          </p>

          <h3>Y a-t-il des contre indications ? </h3>
          <p>
            NON ! Tous les patients peuvent bénéficier de cette technique. Il n’existe pas de contre indications. Au
            contraire, d’éventuels problèmes de santé peuvent rendre cette technique essentielle à une bonne
            cicatrisation.
          </p>
        </Col>
      </Row>
    </WidthLimiter>
  );
};

export default PRF;

const ImagesStepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  & > figure {
    & > img {
      height: 72px;
    }

    & > figcaption {
      margin-top: 12px;
    }
  }
`;
