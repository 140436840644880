import { Title, WidthLimiter } from '@atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Col, Row } from 'antd';
import { useTitle } from '@utils';

const Periodontology = (): JSX.Element => {
  useTitle('Maladies parodontales');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Les maladies parodontales</Title>
        <p>
          On regroupe sous le terme de maladies parodontales les maladies touchant les tissus de soutien des dents
          (gencive, os, ligament). Souvent exprimées par les patients par "les dents qui se déchaussent"
        </p>

        <Row gutter={[24, 24]}>
          <Col span={24}>
            <h3>Les gingivités et parodontites</h3>
            <p>
              C'est la plaque dentaire (accumulation de bactéries) qui est le principal agent responsable du
              développement de ces maladies parodontales.
            </p>

            <ul>
              <li>
                La plaque dentaire se dépose entre chaque brossage. En cas de techniques de brossage inadaptées ou
                inefficaces, cette plaque s'accumule au niveau gingival et se transforme en tartre au bout de 72 heures
                en se calcifiant.
              </li>
              <li>
                L'organisme va vouloir se défendre contre ces bactéries. Il y a alors création d'une réaction de défense
                inflammatoire limitée à la gencive : c'est le stade de la <strong>gingivite</strong>.
              </li>
              <li>
                Sous l'effet cumulé de facteurs de risque parodontaux et d'accumulation de plaque et de tartre, la
                gingivite peut évoluer en <strong>parodontite</strong> et atteindre les tissus parodontaux profonds
                (perte osseuse notamment).
              </li>
            </ul>

            <p>
              Les facteurs de risque peuvent être génétiques, environnementaux (tabac…), locaux (mauvaise occlusion,
              malpositions dentaires, bruxisme) ou liés à la santé générale (diabète non stabilisé, grossesse...).
            </p>

            <p>
              <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
              <strong>Signes fréquents de la gingivite</strong> : la gencive est rouge, lisse, gonflée, elle saigne
              facilement au contact et parfois même spontanément. Une gencive saine doit être rosée et adhérente à l'os
              sous-jacent.
            </p>

            <h4>Traitement</h4>
            <ul>
              <li>
                Conseils pour une hygiène adaptée, avec utilisation de fil dentaire et de brossettes interdentaires
              </li>
              <li>Détartrage annuel effectué au cabinet</li>
              <li>Emploi éventuel de bains de bouche antiseptiques, sur prescription et pour une courte durée</li>
            </ul>

            <p>
              <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', opacity: 0.65 }} />
              <strong>Signes de la parodontite</strong> : un « déchaussement » des dents, c'est-à-dire que l'os de
              soutien de la dent perd de la hauteur et la dent apparaît alors plus longue. Des mobilités, des
              déplacements dentaires, des espaces interdentaires qui s'élargissent sont des signes d'alerte.
            </p>

            <h4>Traitement</h4>
            <ul>
              <li>l'objectif du traitement est la disparition de la poche parodontale</li>
            </ul>
          </Col>

          <Col span={24}>
            <h3>Les récessions pardoontales</h3>
            <p>
              Il s'agit d'une modification de la gencive au niveau du collet de la dent. Lors d'une récession
              parodontale, la gencive se rétracte, si bien que la racine se dénude progressivement. La gêne peut être
              seulement esthétique mais aussi entraîner une hypersensibilité dentinaire. Le traitement consiste en :
            </p>
            <ul>
              <li>Une adaptation de la méthode de brossage à cette situation de fragilité gingivale</li>
              <li>Un suivi attentif de l'évolution</li>
            </ul>

            <p>
              <strong>A noter</strong> : en cas de perte gingivale importante et/ou inesthétique, une greffe de gencive
              pourra être proposée.
            </p>
          </Col>

          <Col span={24}>
            <h3>L'abcès parodontal</h3>
            <p>Il s'agit d'une infection aiguë localisée dans une poche parodontale.</p>

            <p>
              <strong>Signes</strong> : gonflement, rougeur, fortes douleurs. La dent peut être mobile.
            </p>
            <p>
              <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '8px', opacity: 0.65 }} />
              Un traitement d'urgence s'impose.
            </p>
          </Col>

          <Col span={24}>
            <h3>Nos conseils</h3>
            <ul>
              <li>
                Le parodonte étant peu innervé (beaucoup moins qu'une dent), les gingivites et les parodontites sont peu
                douloureuses et se développent le plus souvent à bas bruit.
              </li>
              <li>
                Pour prévenir la parodontite, accompagnez votre brossage des dents d'un léger brossage des gencives en
                allant toujours de la gencive vers la dent.
              </li>
            </ul>
          </Col>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default Periodontology;
