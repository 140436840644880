import { Title, WidthLimiter } from '@atoms';
import {
  CabinetAccueil,
  CabinetAttente,
  CabinetBleuArctique,
  CabinetBlocOperatoire,
  CabinetConsult,
  CabinetRadiologie,
  CabinetRepos,
  CabinetSableChaud,
  CabinetVertForet,
  CoinHygiene,
} from '@images/cabinet';
import { Image } from 'antd';
import { useTitle } from '@utils';
import { Col, Row } from 'antd';
import React from 'react';

const OurCabinet = (): JSX.Element => {
  useTitle('Notre cabinet');
  return (
    <>
      <WidthLimiter style={{ padding: '24px 0' }}>
        <Title>Notre cabinet</Title>
        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Col md={16} xs={24}>
            <p>
              D'inspiration zen et détente, le cabinet se veut accueillant et chaleureux. Idéalement situé en plein
              centre-ville de Château Thierry, dans un Pôle Santé où vous trouverez d'autres professionnels de santé,
              les Drs LALY et RETTEL ont imaginé un lieu où chacun pourra s'y sentir chez lui.
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
          <Col md={12} xs={24}>
            <h3>L'accueil</h3>
            <p>
              C'est le premier lieu de passage indispensable lors de votre arrivée. Gardé par Anne, notre secrétaire,
              illuminé de notre logo et sublimé par nos arbres de vie, vous y ferez toutes vos démarches
              administratives. Que vous veniez pour la première fois ou pour un suivi, n'oubliez pas de saluer Anne en
              arrivant (ou parfois Cathie), au risque de ne pas être enregistré...
            </p>
          </Col>
          <Col md={10} xs={24}>
            <Image src={CabinetAccueil} alt="Accueil" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle" className="revert">
          <Col md={10} xs={24}>
            <Image src={CabinetAttente} alt="Salle d'attente" style={{ width: '100%' }} />
          </Col>
          <Col md={12} xs={24}>
            <h3>La salle d'attente</h3>
            <p>
              Pour patienter jusqu'à l'heure de votre rendez-vous, ce lieu se veut cocooning et chaleureux. Lecteurs
              assidus, des magazines sont à votre disposition. Doux rêveurs, laissez-vous emporter par nos diffusions
              d'images envoutantes et colorées, bercées par une douce musique qui vous fera voyager.
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
          <Col md={12} xs={24}>
            <h3>Deux bureaux de consultation, situés face à l'accueil</h3>
            <p>
              Lors de votre première consultation, le Docteur prendra le temps de vous y accueillir pour y faire votre
              connaissance et connaitre votre motif de consultation. Nous accordons beaucoup d'importance à la
              communication. Bien se connaitre permet d'établir une relation de confiance et ainsi une meilleure
              compréhension des plans de traitement proposés.
            </p>
            <p>
              Ces lieux se veulent rassurants, exempts de tout instrument dentaire, vous permettant de vous exprimer
              sans crainte, en toute sérénité et confidentialité.
            </p>
          </Col>
          <Col md={10} xs={24}>
            <Image src={CabinetConsult} alt="Salle de consultation" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle" className="revert">
          <Col md={10} xs={24}>
            <Image src={CabinetBleuArctique} alt="Cabinet bleu arctique" style={{ width: '100%' }} />
          </Col>
          <Col md={12} xs={24}>
            <h3>Trois salles au décor doux et apaisant</h3>
            <p>
              Le cabinet possède 3 salles de soin dont le décor se veut doux et apaisant, équipés chacune d'un écran au
              plafond dont les diffusions changent avec les saisons.
            </p>

            <h4>Le cabinet bleu arctique</h4>
            <p>
              Rdv dans les montagnes, où vous serez soigné dans un décor nordique, sous les yeux joyeux de Nayok et
              Blue, Huskies régnant en maîtres sur l'antre du Docteur.
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
          <Col md={12} xs={24}>
            <h4>Le cabinet vert forêt</h4>
            <p>
              Dans un lieu plus lumineux et verdoyant, près d'un lac rendant l'atmosphère apaisante, et prompte à la
              rêverie, les soins du Docteur passeront presque inaperçus.
            </p>
          </Col>
          <Col md={10} xs={24}>
            <Image src={CabinetVertForet} alt="Cabinet vert forêt" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle" className="revert">
          <Col md={10} xs={24}>
            <Image src={CabinetSableChaud} alt="Cabinet sable chaud" style={{ width: '100%' }} />
          </Col>
          <Col md={12} xs={24}>
            <h4>Le cabinet sable chaud</h4>
            <p>
              Dans une ambiance plus Zen. Ecoutez votre inconscient et voyagez avec votre “moi” intérieur pour des soins
              en toute sérénité. Interrogez-vous sur l'œuvre de V.G. “Rêverie arborescente” symbolisant l'effervescence
              des esprits hypersensibles pour lesquels la méditation est source de canalisation.
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
          <Col md={12} xs={24}>
            <h3>Le bloc opératoire</h3>
            <p>
              Avec son SAS attenant, il vous accueille pour des soins de chirurgie et d'implantologie dans un cadre plus
              sobre et aseptisé. Si vous y pensez, observez la galerie photos en passant dans le SAS. Véritables
              souvenirs de leur démarrage, vous y verrez les docteurs et leurs assistantes, habillés pour les soins et
              absorbés par leur passion de la chirurgie
            </p>
          </Col>
          <Col md={10} xs={24}>
            <Image src={CabinetBlocOperatoire} alt="Bloc opératoire" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle" className="revert">
          <Col md={10} xs={24}>
            <Image src={CabinetRepos} alt="Salle de repos" style={{ width: '100%' }} />
          </Col>
          <Col md={12} xs={24}>
            <h3>Petite salle de repos</h3>
            <p>
              Une petite salle de repos et sa méridienne de l'espace vous permettra de trouver un peu de calme pour vous
              ressourcer après les soins ou les chirurgies. Partez pour une exploration d'un univers plus aérien, dans
              une bulle hors du temps.
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle">
          <Col md={12} xs={24}>
            <h3>La salle de radiologie</h3>
            <p>
              La salle de radiologie ou “salle des trophées” (en référence aux divers diplômes y étant accrochés),
              permettra de réaliser PANORAMIQUE et CBCT sur place. Pour s'occuper pendant les clichés, essayer de
              repérer quel diplôme appartient à qui !
            </p>
          </Col>
          <Col md={10} xs={24}>
            <Image src={CabinetRadiologie} alt="Salle de radiologie" style={{ width: '100%' }} />
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px', marginBottom: '24px' }} align="middle" className="revert">
          <Col md={10} xs={24}>
            <Image src={CoinHygiene} alt="Coin hygiène" style={{ width: '100%' }} />
          </Col>
          <Col md={12} xs={24}>
            <h3>Coin hygiène</h3>
            <p>
              L'heure de votre RDV ne vous a pas permis de repasser chez vous après le travail ou la sortie de l'école ?
              Pas de panique, un coin hygiène est à votre disposition près de la salle d'attente. N'hésitez pas à
              demander brosse à dent et dentifrice à l'accueil !
            </p>
          </Col>
        </Row>
      </WidthLimiter>
    </>
  );
};

export default OurCabinet;
